import React, { useState } from "react";
import "./Allergies.less";
import { createAllergyType } from "../../../services/api";

export default function Allergies() {
  const [image, setImage] = useState("");
  const [name, setName] = useState("");

  const handleSubmit = (e) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("image", image);
    e.preventDefault();
    console.log(formData);
    createAllergyType(formData);
  };

  return (
    <div>
      <form onSubmit={handleSubmit} method="post">
        <input
          placeholder="name"
          type="text"
          name="name"
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="file"
          name="image"
          onChange={(e) => setImage(e.target.files[0])}
        />
        <input type="submit" value="Submit" />
      </form>
      Create Allergy Types
    </div>
  );
}
