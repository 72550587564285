import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw } from 'draft-js';

// const EditorComponent = () => <Editor />

// export default EditorComponent;

// import React from 'react';

const EditorComponent = () => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    }

    console.log(convertToRaw(editorState.getCurrentContent()));

    return (
        <Editor editorState={editorState} onEditorStateChange={onEditorStateChange} />
    );
};

export default EditorComponent;