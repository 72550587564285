import React, { useState } from "react";
import { useEffect } from "react";
import {  deleteBusinessType, getAllBusinessType } from "../../../services/api";
import { Layout } from "antd";
import BusinessTypeTable from "../../../components/tables/AllBusinessTypeTable";

const AllBusinessType = () => {
  const [allType, setAllType] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [newRecord, setNewRecord] = useState(false);

  useEffect(() => {
    AllBusinessType();
  }, []);

  const AllBusinessType = async () => {
    
    setShowSkeleton(true);
    try {
      const { data, status } = await getAllBusinessType();
      if (status) {
        // console.log(data.data);
        setAllType(data.data);
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  };

  const deleteCredential = async (id) => {
    // console.log(id);
    setShowSkeleton(true);
    try {
      const { status } = await deleteBusinessType(id);
      if (status) {
    
        AllBusinessType();
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.error({ error });
    }
  };

  return (
    <div>
      <Layout.Content className="_tableContainer">
        <BusinessTypeTable
          showSkeleton={showSkeleton}
          data={allType}
          newRecord={newRecord}
          deleteCredential={deleteCredential}
        />
      </Layout.Content>
    </div>
  );
};

export default AllBusinessType;
