import React, { useContext, useState, useEffect, useMemo } from "react";
import "./dashboard.less";
import { Layout, Typography } from "antd";
import { Tabs, Card, Col, Row } from "antd";
import RowCard from "../../components/RowCard/RowCard";
import { getDashboardInfoToday, getDashboardInfoSevenDays } from "../../services/api";

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

const { Title, Text } = Typography;

export default function Dashboard() {

  // Dashboard Info Today
  const [dashboardToday, setDashboardToday] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [newRecord, setNewRecord] = useState(false);

  useEffect(() => {
    dashboardInfoToday();
  }, []);

  const dashboardInfoToday = async () => {
    setShowSkeleton(true);
    try {
      const { data, status } = await getDashboardInfoToday();
      if (status) {
        setDashboardToday(data);
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  };

  // Dashboard Info Last Seven Days
  const [dashboardSevenDays, setDashboardSevenDays] = useState([]);

  useEffect(() => {
    dashboardInfoSevenDays();
  }, []);

  const dashboardInfoSevenDays = async () => {
    setShowSkeleton(true);
    try {
      const { data, status } = await getDashboardInfoSevenDays();
      if (status) {
        console.log(data);
        setDashboardSevenDays(data);
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  };

  const dashboardDataOfToday = [
    {
      title: "ACTIVE USERS",
      number: dashboardToday.users,
    },

    {
      title: "ACTIVE RESTAURANTS",
      number: dashboardToday.restaurants,
    },

    {
      title: "ACTIVE SALES",
      number: dashboardToday.sales,
    },
    {
      title: "ACTIVE REVENUE",
      number: "N/A",
    },
  ];

  const dashboardDataOfLastSevenDays = [
    {
      title: "ACTIVE USERS",
      number: dashboardSevenDays.users,
    },

    {
      title: "ACTIVE RESTAURANTS",
      number: dashboardSevenDays.restaurants,
    },

    {
      title: "ACTIVE SALES",
      number: dashboardSevenDays.sales,
    },
    {
      title: "ACTIVE REVENUE",
      number: "N/A",
    },

  ];

  return (
    <div>
      <Layout.Content className="_container">
        <Tabs className="tab-layout" onChange={callback} type="card">
          <TabPane className="tab-box" tab="Today" key="1">
            <RowCard items={dashboardDataOfToday} />
          </TabPane>
          <TabPane tab="Last 7 Days" key="2">
            <RowCard items={dashboardDataOfLastSevenDays} />
          </TabPane>
          {/* <TabPane tab="Last 30 Days" key="3">
            <RowCard items={item3} />
          </TabPane> */}
        </Tabs>
      </Layout.Content>
    </div>
  );
}

