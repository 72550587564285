import React, { useEffect, useState } from 'react';
import { deleteOrder, getSevenDaysOrders, getCurrentWeekOrder, deleteSevenDaysOrders, deleteThirtyDaysOrders } from '../../../services/api';
import './All.less';
import {
  Button,
  Layout,
  Tabs,
} from 'antd';
import OrdersTable from '../../../components/tables/OrdersTable';
import { DeleteOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

export default function AllOrders() {

  function callback(key) {
    console.log(key);
  }

  const [sevenDaysOrders, setSevenDaysOrders] = useState([]);
  const [currentWeekOrder, setCurrentWeekOrder] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [newRecord, setNewRecord] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(async () => {
    setShowSkeleton(true);
    try {
      const { data, status } = await getSevenDaysOrders();
      if (status) {
        setSevenDaysOrders(data.data.orders);
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  }, []);

  useEffect(async () => {
    setShowSkeleton(true);
    try {
      const { data, status } = await getCurrentWeekOrder();
      if (status) {
        setCurrentWeekOrder(data.data.orders);
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  }, []);

  const deleteOrderFromSevendays = async (id) => {
    await deleteOrder(id);
    setShowSkeleton(true);
    try {
      const { data, status } = await getSevenDaysOrders();
      if (status) {
        setSevenDaysOrders(data.data.orders);
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  };

  const deleteOrderFromThirtydays = async (id) => {
    await deleteOrder(id);
    setShowSkeleton(true);
    try {
      const { data, status } = await getCurrentWeekOrder();
      if (status) {
        setCurrentWeekOrder(data.data.orders);
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  };

  const handleDeleteSevenDaysOrders = async () => {
    await deleteSevenDaysOrders();
    setShowSkeleton(true);
    try {
      const { data, status } = await getSevenDaysOrders();
      if (status) {
        setSevenDaysOrders(data.data.orders);
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  }

  const handleDeletecurrentWeekOrder = async () => {
    // await deletecurrentWeekOrder();
    setShowSkeleton(true);
    try {
      const { data, status } = await getCurrentWeekOrder();
      if (status) {
        setCurrentWeekOrder(data.data.orders);
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  }

  return (
    <div>
      <Tabs onChange={callback} type="card">
        <TabPane tab="Current Week Orders" key="1">
          <Layout.Content className="_tableContainer">
            
              <OrdersTable
              showSkeleton={showSkeleton}
              data={currentWeekOrder}
              newRecord={newRecord}
              deleteAnOrder={deleteOrderFromThirtydays}
            />
           
          </Layout.Content>
        </TabPane>
        <TabPane tab="Last Week Orders" key="2">
          <Layout.Content className="_tableContainer">
            
          <OrdersTable
              showSkeleton={showSkeleton}
              data={sevenDaysOrders}
              newRecord={newRecord}
              deleteAnOrder={deleteOrderFromSevendays}
            />
             {/* <div className="marginTop">
              <Button type="primary" icon={<DeleteOutlined />} size="large" onClick={handleDeleteSevenDaysOrders}>
                Delete Last Week Orders
              </Button>
            </div> */}
          </Layout.Content>
        </TabPane>
      </Tabs>
    </div>
  );
}
