import React, { useEffect, useRef, useState } from "react";
import { Layout, Tabs, Row, Col, Button, Space, Switch, Table } from "antd";
import InvoiceTable from "./../../../components/tables/InvoiceTable";
import {
  getSevenDaysInvoice,
  getThirtyDaysInvoice,
} from "../../../services/api";
import { useParams } from "react-router-dom";
import "./All.less";
import ReactToPrint from "react-to-print";
import logo from "../../../assets/logo.png";

const { TabPane } = Tabs;

const Invoice = () => {
  function callback(key) {
    console.log(key);
  }

  const { id } = useParams();

  const [sevenDaysInvoiceInfos, setSevenDaysInvoiceInfos] = useState([]);
  const [thirtyDaysInvoiceInfos, setThirtyDaysInvoiceInfos] = useState([]);
  const [sevenDaysInvoiceOrders, setSevenDaysInvoiceOrders] = useState([]);
  const [thirtyDaysInvoiceOrders, setThirtyDaysInvoiceOrders] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [newRecord, setNewRecord] = useState(false);

  useEffect(async () => {
    setShowSkeleton(true);
    try {
      const { data, status } = await getSevenDaysInvoice(id);
      if (status) {
        console.log(data.data.invoice);
        setSevenDaysInvoiceInfos(data.data.invoice);
        setSevenDaysInvoiceOrders(data.data.orders);
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  }, []);

  useEffect(async () => {
    setShowSkeleton(true);
    try {
      const { data, status } = await getThirtyDaysInvoice(id);
      if (status) {
        console.log(data.data.invoice);
        setThirtyDaysInvoiceInfos(data.data.invoice);
        setThirtyDaysInvoiceOrders(data.data.orders);
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  }, []);

  const sevendays_data1 = [
    {
      key: "1",
      title: "Booking Orders",
      value: sevenDaysInvoiceInfos.orderCount?.bookings,
    },
    {
      key: "2",
      title: "Collection Orders",
      value: sevenDaysInvoiceInfos.orderCount?.collection,
    },
    {
      key: "3",
      title: "Dine In Orders",
      value: sevenDaysInvoiceInfos.orderCount?.dineIn,
    },
    {
      key: "4",
      title: "Home Delivery Orders",
      value: sevenDaysInvoiceInfos.orderCount?.homeDelivery,
    },
  ];

  const sevendays_data2 = [
    {
      key: "1",
      title: "Gross Income",
      value: sevenDaysInvoiceInfos.grossTotal?.toFixed(2),
    },
    {
      key: "2",
      title: "Cash Collected",
      value: sevenDaysInvoiceInfos.cashCollected?.toFixed(2),
    },
    {
      key: "3",
      title: "FA Charge",
      value: sevenDaysInvoiceInfos.faCharges?.toFixed(2),
    },
    {
      key: "4",
      title: "Order Charge",
      value: sevenDaysInvoiceInfos.inhouseFaCharge?.toFixed(2),
    },
    {
      key: "5",
      title: "Service Charge",
      value: sevenDaysInvoiceInfos.serviceCharge?.toFixed(2),
    },
    {
      key: "6",
      title: "Rider Charge",
      value: sevenDaysInvoiceInfos.riderCharge?.toFixed(2),
    },
    {
      key: "7",
      title: "Booking Charge",
      value: sevenDaysInvoiceInfos.bookingCharge?.toFixed(2),
    },
    {
      key: "8",
      title: "Bank Charge",
      value: sevenDaysInvoiceInfos.bankCharge?.toFixed(2),
    },
    {
      key: "9",
      title: "Delivery Charge",
      value: sevenDaysInvoiceInfos.deliveryCharge?.toFixed(2),
    },
    {
      key: "10",
      title: "Total",
      value: sevenDaysInvoiceInfos.total?.toFixed(2),
    },
  ];

  const thirtyDaysInvoiceInfos_data1 = [
    {
      key: "1",
      title: "Booking Orders",
      value: thirtyDaysInvoiceInfos.orderCount?.bookings,
    },
    {
      key: "2",
      title: "Collection Orders",
      value: thirtyDaysInvoiceInfos.orderCount?.collection,
    },
    {
      key: "3",
      title: "Dine In Orders",
      value: thirtyDaysInvoiceInfos.orderCount?.dineIn,
    },
    {
      key: "4",
      title: "Home Delivery Orders",
      value: thirtyDaysInvoiceInfos.orderCount?.homeDelivery,
    },
  ];

  const thirtyDaysInvoiceInfos_data2 = [
    {
      key: "1",
      title: "Gross Income",
      value: thirtyDaysInvoiceInfos.grossTotal?.toFixed(2),
    },
    {
      key: "2",
      title: "Cash Collected",
      value: thirtyDaysInvoiceInfos.cashCollected?.toFixed(2),
    },
    {
      key: "3",
      title: "FA Charge",
      value: thirtyDaysInvoiceInfos.faCharges?.toFixed(2),
    },
    {
      key: "4",
      title: "Order Charge",
      value: thirtyDaysInvoiceInfos.inhouseFaCharge?.toFixed(2),
    },
    {
      key: "5",
      title: "Service Charge",
      value: thirtyDaysInvoiceInfos.serviceCharge?.toFixed(2),
    },
    {
      key: "6",
      title: "Rider Charge",
      value: thirtyDaysInvoiceInfos.riderCharge?.toFixed(2),
    },
    {
      key: "7",
      title: "Booking Charge",
      value: thirtyDaysInvoiceInfos.bookingCharge?.toFixed(2),
    },
    {
      key: "8",
      title: "Bank Charge",
      value: thirtyDaysInvoiceInfos.bankCharge?.toFixed(2),
    },
    {
      key: "9",
      title: "Delivery Charge",
      value: sevenDaysInvoiceInfos.deliveryCharge?.toFixed(2),
    },
    {
      key: "10",
      title: "Total",
      value: thirtyDaysInvoiceInfos.total?.toFixed(2),
    },
  ];

  return (
    <div className="marginBottom">
      <hr />
      <Tabs onChange={callback} type="card">
        <TabPane tab="Last 7 Days" key="1">
          <Layout.Content className="_tableContainer">
            <InvoiceTable
              showSkeleton={showSkeleton}
              newRecord={newRecord}
              data={sevenDaysInvoiceOrders}
            />
          </Layout.Content>
          <InvoiceSlip data1={sevendays_data1} data2={sevendays_data2} />
        </TabPane>

        {/* <TabPane tab="Last 30 Days" key="2">
          <Layout.Content className="_tableContainer">
            <InvoiceTable
              showSkeleton={showSkeleton}
              newRecord={newRecord}
              data={thirtyDaysInvoiceOrders}
            />
          </Layout.Content>
          <InvoiceSlip
            data1={thirtyDaysInvoiceInfos_data1}
            data2={thirtyDaysInvoiceInfos_data2}
          />
        </TabPane> */}
      </Tabs>

      <div style={{ textAlign: "right", margin: "30px auto", maxWidth: "80%" }}>
        <Button type="primary" size="large" className="marginBottom">
          Pay Restaurant
        </Button>
      </div>
    </div>
  );
};

const InvoiceSlip = (props) => {
  const { data1, data2 } = props;

  let componentRef = useRef();

  const columns = [
    {
      title: "",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "",
      dataIndex: "value",
      key: "value",
    },
  ];

  return (
    <div className="invoice-printer" ref={(el) => (componentRef = el)}>
      <Space
        direction="horizontal"
        style={{ width: "100%", justifyContent: "space-between" }}
      >
        <div>
          <img src={logo} alt="" style={{ width: "120px", height: "80px" }} />
          <p className="foodapps-address">
            2a Baconsfield Street, Newcastle Upon Tyne, NE45JN
          </p>
        </div>
        <ReactToPrint
          trigger={() => (
            <Button type="primary" size="small" className="marginBottom">
              Download Invoice
            </Button>
          )}
          content={() => componentRef}
        />
      </Space>
      <Space
        className="custom-spacer-invoice"
        direction="horizontal"
        style={{ width: "100%", justifyContent: "space-between" }}
      >
        <div className="invoice-ribbon" />
        <div className="invoice">Invoice</div>
      </Space>

      <Table columns={columns} dataSource={data1} pagination={false} />
      <Table
        className="charge-table"
        columns={columns}
        dataSource={data2}
        pagination={false}
      />
    </div>
  );
};

export default Invoice;
