import React from 'react'
import './Pending.less'

export default function PendingOrders() {
    return (
        <div>
            Pending
        </div>
    )
}
