import React, { useEffect, useState } from "react";
import { getAllEmployees, deleteEmployee, enableEmployee } from "../../services/api";
import "./Employees.less";

import {
  Button,
  Col,
  Input,
  Layout,
  Radio,
  Row,
  Space,
  Typography,
  message
} from "antd";
import CustomersTable from "./../../components/tables/CustomersTable";
import { CustomeLoader } from "../../components/loader";
import EmployeesTable from "./../../components/tables/EmployeesTable";

export default function Employees() {
  //   const [allEmployees, setAllEmployees] = useState([]);
  //   const [showSkeleton, setShowSkeleton] = useState(false);
  //   const [newRecord, setNewRecord] = useState(false);
  //   const [isLoading, setIsLoading] = useState(false);

  //   useEffect(async () => {
  //     setIsLoading(true);
  //     setShowSkeleton(true);
  //     try {
  //       const { data, status } = await getAllEmployees();
  //       if (status) {
  //         setAllEmployees(data.data);
  //         setIsLoading(false);
  //         setShowSkeleton(false);
  //       }
  //     } catch (error) {
  //       setIsLoading(false);
  //       setShowSkeleton(false);
  //       console.log({ error });
  //     }
  //   }, []);

  const [allEmployees, setAllEmployees] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [newRecord, setNewRecord] = useState(false);

  useEffect(() => {
    allEmployeesList();
  }, []);

  const allEmployeesList = async () => {
    // console.log("hello");
    setShowSkeleton(true);
    try {
      const { data, status } = await getAllEmployees();
      if (status) {
        console.log(data.data);
        setAllEmployees(data.data);
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  };

  const deleteAnEmployee = async (id) => {
    console.log(id);
    setShowSkeleton(true);
    try {
      const { data, status } = await deleteEmployee(id);
      if (status) {
        allEmployeesList();
        setShowSkeleton(false);
        message.error("Employee is deleted successfully!");
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  };
  // const handleTableChange = (pagination) => {
  //   console.log("handleTableChange");
  //   allEmployeesList();
  // };

  const enableAnEmployee = async (id, isEnable) => {
 
    setShowSkeleton(true);
    try {
      const { data, status } = await enableEmployee(id, isEnable);
      if (status) {
        allEmployeesList();
        setShowSkeleton(false);

        if(isEnable.isEnable){
          message.error("Employee is enabled successfully!");
        } else {
          message.error("Employee is disabled successfully!");
        }
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  }

  return (
    <div>
      <Layout.Content className="_tableContainer">
        <EmployeesTable
          showSkeleton={showSkeleton}
          data={allEmployees}
          newRecord={newRecord}
          deleteAnEmployee={deleteAnEmployee}
          enableAnEmployee={enableAnEmployee}
        />
      </Layout.Content>
    </div>
  );
}
