import {
  Table,
  Skeleton,
  Space,
  Button,
  Input,
  Modal,
  Switch,
  Form,
  Radio,
} from "antd";
import moment from "moment";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import "./EmployeesTable.less";
import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import { enableEmployee } from "../../services/api";
import allEmployeesList from '../../pages/Employees/index'

const EmployeesTable = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const [disabled, setDisabled] = useState(true);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => console.log(node)}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
      // render: name => `${name.first} ${name.last}`,
    },
    {
      title: "Email",
      dataIndex: "email",
      ...getColumnSearchProps("email"),
      // render: email => `${email.first} ${email.last}`,
    },
    {
      title: "Phone",
      dataIndex: "mobileNo",
      ...getColumnSearchProps("mobileNo"),
      // render: mobileNo => `${mobileNo.first} ${mobileNo.last}`,
    },
    {
      title: "Enable/disable",
      dataIndex: "enableDisable",
      filters: [{ text: "enableDisable", value: "enableDisable" }],
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  const { confirm } = Modal;

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure delete this customer?",
      icon: <ExclamationCircleOutlined />,
      //   content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        // console.log('OK');
        props.deleteAnEmployee(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const disable = (id) => {
    console.log(id);
  };


  const isEnable = {
    isEnable: true,
  };

  const isDisable = {
    isEnable: false,
  };

  // const [isEnable, setEnable] = useState(true);

  // const updateStatus = (id) =>{
  //   setEnable(!isEnable);
  //   props.enableAnEmployee(id, isEnable);
  // }

  const enable = (id) => {
    console.log(id);
  };

  const dataSource = [
    ...props.data.map((item, i) => {
      return {
        key: i,
        name: (item && item.name) || "-- --",
        email: (item && item.email) || "-- --",
        mobileNo: (item && item.mobileNo) || "-- --",
        enableDisable: (item && item.isEnable ? 
          <Button className="disable-btn" onClick={() => props.enableAnEmployee(item._id, isDisable)}>Disable</Button> :
          <Button className="enable-btn" onClick={() => props.enableAnEmployee(item._id, isEnable)}>Enable</Button> 
        ),
        action: (
          <div>
            <Button
              onClick={() => showDeleteConfirm(item && item._id)}
              type="dashed"
            >
              Delete
            </Button>
            <Link to={`/employee/edit/${item._id}`}>
              <Button type="primary">Edit</Button>
            </Link>
            ;
          </div>
        ),
      };
    }),
  ];

  const handleTableChange = () => {
    console.log("handleTableChange");
  };

  const handleNavigation = (record, index) => {
    return {
      onClick: () => {
        // console.log("Record Clicked!");
        // history.push(`/dashboard/applicant?id=${record?.checksId}`)
      },
    };
  };

  return props.showSkeleton ? (
    <div style={{ padding: "20px" }}>
      <Space
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        justify="space-between"
      >
        <Skeleton.Button
          style={{ width: 110 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Button
          style={{ width: 110 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Button
          style={{ width: 110 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Button
          style={{ width: 110 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Avatar
          style={{ width: 110 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Avatar
          style={{ width: 110 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Avatar
          style={{ width: 110 }}
          active
          size={"small"}
          shape={"square"}
        />
      </Space>
      <div style={{ marginTop: "30px" }}>
        <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
      </div>
      <div style={{ marginTop: "25px" }}>
        <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
      </div>
      <div style={{ marginTop: "25px" }}>
        <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
      </div>
      <div style={{ marginTop: "25px" }}>
        <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
      </div>
      <div style={{ marginTop: "25px" }}>
        <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
      </div>
    </div>
  ) : (
    <Table
      rowClassName={(record, index) => {
        if (props?.newRecord && index === 0) {
          let a = "table-row-color";
          return a;
        }
      }}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      search={false}
      onRow={handleNavigation}
      rowClassName={"cursor"}
      onChange={handleTableChange}
    />
  );
};

export default EmployeesTable;
