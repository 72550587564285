import React from 'react'
import './InProgress.less'

export default function InProgress() {
    return (
        <div>
            InProgress
        </div>
    )
}
