import React, { useContext, useEffect, useState } from 'react';
import './welcome.less';
import ForgetPassword from './ForgetPassword';
import LoginSignup from './LoginSignup';
import Verification from './Verification';
import CreateNewPassword from './CreateNewPassword';
import Congratulations from './Congratulation';

import { Row, Col, Typography, message } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import { AuthContext } from '../../context/Auth';
import { CustomeLoader, StyledLoader } from '../../components/loader';
import queryString from 'query-string';
import { login } from './../../services/api';
import { isMobile } from 'react-device-detect';
import ErrorList from 'antd/lib/form/ErrorList';
import VerificationSent from './verificationSent';

const { Text } = Typography

export default function Welcome(props) {
    const history = useHistory();
    const location = useLocation();
    const auth = useContext(AuthContext);
    const [loading, setLoading] = useState(false)
    const [showPage, setShowPage] = useState('login')
    const [type, setType] = useState('login')
    const [email, setEmail] = useState('')
    const [company, setCompany] = useState('')
    const [userId, setUserId] = useState('')

    const { from } = location.state || { from: { pathname: "/dashboard" } };

    const login = (data) => {
        setLoading(true)
        auth.signIn(data, () => history.replace(from), () => setLoading(false));
    };

    const signup = async (values) => {
        // if (values.tnc) {
        //     setLoading(true)
        //     // auth.createOrganization(data, () => setType("login"), () => setLoading(false));
        //     try {
        //         const { data, status } = await createorganization(values)
        //         if (status) {
        //             setLoading(false)
        //             setType("login")
        //             setShowPage("verificationSent")
        //         }
        //     } catch (error) {
        //         setLoading(false)
        //         console.log({ error })
        //     }
        // } else {
        //     message.error("Please accept terms and conditions!")
        // }
    };

    const forgetPassword = async (value) => {
        setLoading(true)
        try {
            // const { data, status } = await resetPassword(value)
            // if (status === 200) {
            //     setLoading(false)
            //     // setShowPage("login")
            // }
        } catch (error) {
            setLoading(false)
        }
    };

    const createNewPassword = async (value) => {
        setLoading(true)
        const updateValues = {
            ...value,
            email: email
        }
        // const { data, status } = await updatePassword(updateValues, userId)
        // if (status === 200) {
        //     setLoading(false)
        //     setShowPage("login")
        // }
    };

    const backHandler = (key) => {
        switch (key) {
            case "forgotPassword":
                setShowPage("login")
                break;
            case "verification":
                setShowPage("login")
                break;
            case "create-new-password":
                setShowPage("forgotPassword")
                break;
            case "congratulations":
                setShowPage("login")
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        // localStorage.removeItem("foodapps-user")
        const { company, email, id } = queryString.parse(history.location.search);
        setUserId(id || "")
        setEmail(email || "")
        setCompany(company || "")
        if (window.location.search.includes("page=verification")) {
            setShowPage("verification")
        } else if (window.location.search.includes("page=create-new-password")) {
            setShowPage("create-new-password")
        } else if (window.location.search.includes("page=congratulations")) {
            setShowPage("congratulations")
        }
        // else if (window.location.search.includes("page=signup")) {
        //     setType("signup")
        // }
    }, [])

    return (
        <div>
            {
                loading &&
                <StyledLoader />
            }
            <Row>
                <Col className='left-side' span={isMobile ? 24 : 12}>
                    <Row className="logo-container">
                        <div className='logo'>
                            {/* <img src="assets/logo.png" alt="sabhi" className="light" /> */}
                            <img width="200" height="100" src="assets/logo.png" alt="sabhi" />
                        </div>
                    </Row>
                    <Row>
                        <Col className="back-container" onClick={() => backHandler(showPage)}>
                            {
                                showPage !== "login" &&
                                <>
                                    <img src="assets/back.svg" alt="back" />
                                    <Text className="back-text">
                                        Back
                                    </Text>
                                </>
                            }
                        </Col>
                    </Row>


                    {
                        showPage === "forgotPassword" &&
                        <Row justify="center">
                            <ForgetPassword resetPassword={forgetPassword} setShowPage={setShowPage} />
                        </Row>
                    }
                    {
                        showPage === "login" &&
                        <Row justify="center">
                            <LoginSignup
                                type={type}
                                setType={setType}
                                setShowPage={setShowPage}
                                login={login}
                                company={company}
                                email={email}
                            />
                        </Row>
                    }
                    {
                        showPage === "verification" &&
                        <Row justify="center">
                            <Verification setShowPage={setShowPage} />
                        </Row>
                    }
                    {
                        showPage === "create-new-password" &&
                        <Row justify="center">
                            <CreateNewPassword createNewPassword={createNewPassword} setShowPage={setShowPage} />
                        </Row>
                    }
                    {
                        showPage === "congratulations" &&
                        <Row justify="center">
                            <Congratulations setShowPage={setShowPage} />
                        </Row>
                    }
                    {
                        showPage === "verificationSent" &&
                        <Row justify="center">
                            <VerificationSent setShowPage={setShowPage} />
                        </Row>
                    }
                </Col>
                {
                    !isMobile &&
                    <Col align="center" justify="center" className='right-side' span={12}>
                        {
                            showPage === "forgotPassword" ?
                                <img src="assets/rafiki2.svg" alt="sabhi" />
                                :
                                <img src="https://foodapps.uk/images/backgrounds/food2.png" alt="sabhi" />
                        }
                    </Col>
                }
            </Row>
        </div>
    )
}
