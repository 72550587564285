import { Table, Skeleton, Space, Button, Input } from "antd";
import moment from "moment";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import "./CustomersTable.less";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { DeleteOutlined } from "@ant-design/icons";
import { deleteOrder } from "../../services/api/index";

const OrdersTable = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  // const [pagination, setPagination] = useState({
  //     current: 1,
  //     pageSize: 10,
  // })

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => console.log(node)}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "Order Number",
      dataIndex: "orderNumber",
      ...getColumnSearchProps("orderNumber"),
      // render: id => `${id.first} ${id.last}`,
    },
    {
      title: "Order Type",
      dataIndex: "orderType",
      ...getColumnSearchProps("orderType"),
      // render: restaurant => `${restaurant.first} ${restaurant.last}`,
    },
    {
      title: "Order At",
      dataIndex: "orderAt",
      sorter: true,
    },
    {
      title: "Price",
      dataIndex: "price",
      filters: [{ text: "price", value: "price" }],
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: [{ text: "status", value: "status" }],
    },
    {
      title: "Method",
      dataIndex: "method",
      filters: [{ text: "method", value: "method" }],
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  const dataSource = [
    ...props.data?.map((item, i) => {
      console.log(item);
      return {
        key: i,
        orderNumber: (item && item.orderNumber) || "-- --",
        orderType: (item && item.orderType) || "-- --",
        orderAt:
          (item && moment(item.createdAt).format("DD-MM-YYYY")) ||
          "N/A",
        price: (item && "£"+Number(item.totalPrice).toFixed(2)) || "-- --",
        method: (item && item.paymentMethod) || "-- --",
        status: (item && item.orderStatus) || "-- --",
        action: (
          <DeleteOutlined
            onClick={() => {
              props.deleteAnOrder(item._id);
              // console.log(item._id)
            }}
          />
        ),
      };
    }),
  ];

  const handleTableChange = () => {
    console.log("handleTableChange");
  };

  const handleNavigation = (record, index) => {
    return {
      onClick: () => {
        history.push(`/dashboard/applicant?id=${record?.checksId}`);
      },
    };
  };

  return props.showSkeleton ? (
    <div style={{ padding: "20px" }}>
      <Space
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        justify="space-between"
      >
        <Skeleton.Button
          style={{ width: 120 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Button
          style={{ width: 120 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Button
          style={{ width: 120 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Button
          style={{ width: 120 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Avatar
          style={{ width: 120 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Avatar
          style={{ width: 120 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Avatar
          style={{ width: 120 }}
          active
          size={"small"}
          shape={"square"}
        />
      </Space>
      <div style={{ marginTop: "30px" }}>
        <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
      </div>
      <div style={{ marginTop: "25px" }}>
        <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
      </div>
      <div style={{ marginTop: "25px" }}>
        <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
      </div>
      <div style={{ marginTop: "25px" }}>
        <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
      </div>
      <div style={{ marginTop: "25px" }}>
        <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
      </div>
    </div>
  ) : (
    <Table
      rowClassName={(record, index) => {
        if (props?.newRecord && index === 0) {
          let a = "table-row-color";
          return a;
        }
      }}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      search={false}
      pagination={true}
      // onRow={handleNavigation}
      rowClassName={"cursor"}
      onChange={handleTableChange}
    />
  );
};

export default OrdersTable;
