import { Table, Skeleton, Space, Button, Input } from 'antd';
import moment from 'moment';
import { useState, } from 'react';
import { useHistory } from 'react-router-dom';
import './CustomersTable.less'
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';


const InvoiceTable = (props) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const history = useHistory();
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => console.log(node)}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
    };

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            ...getColumnSearchProps('date')
            // render: name => `${name.first} ${name.last}`,
        },
        {
            title: 'Payment Type',
            dataIndex: 'paymentType',
            ...getColumnSearchProps('paymentType')
            // render: email => `${email.first} ${email.last}`,
        },
        {
            title: 'Order Type',
            dataIndex: 'orderType',
            ...getColumnSearchProps('orderType')
            // render: email => `${email.first} ${email.last}`,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            ...getColumnSearchProps('amount')
            // render: mobileNo => `${mobileNo.first} ${mobileNo.last}`,
        },
    ];

    const dataSource = [
        ...props.data?.map((item, i) => {
            return {
                key: i,
                date: (item && moment(item.createdAt).format("DD-MM-YYYY")) || "-- --",
                paymentType: (item && item.paymentMethod) || "-- --",
                orderType: (item && item.orderType) || "-- --",
                amount: (item && item.totalPrice.toFixed(2)) || "-- --",
            };
        })
    ];



    const handleTableChange = () => {
        console.log('handleTableChange');
    }

    const handleNavigation = (record, index) => {
        return {
            onClick: () => {
                console.log('Record Clicked!')
                // history.push(`/dashboard/applicant?id=${record?.checksId}`)
            }
        };
    }

    return (
        props.showSkeleton ?
            <div style={{ padding: "20px" }}>
                <Space style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} justify="space-between">
                    <Skeleton.Button style={{ width: 110 }} active size={"small"} shape={"square"} />
                    <Skeleton.Button style={{ width: 110 }} active size={"small"} shape={"square"} />
                    <Skeleton.Button style={{ width: 110 }} active size={"small"} shape={"square"} />
                    <Skeleton.Button style={{ width: 110 }} active size={"small"} shape={"square"} />
                    <Skeleton.Avatar style={{ width: 110 }} active size={"small"} shape={"square"} />
                    <Skeleton.Avatar style={{ width: 110 }} active size={"small"} shape={"square"} />
                    <Skeleton.Avatar style={{ width: 110 }} active size={"small"} shape={"square"} />
                </Space>
                <div style={{ marginTop: "30px" }}>
                    <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
                </div>
                <div style={{ marginTop: "25px" }}>
                    <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
                </div>
                <div style={{ marginTop: "25px" }}>
                    <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
                </div>
                <div style={{ marginTop: "25px" }}>
                    <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
                </div>
                <div style={{ marginTop: "25px" }}>
                    <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
                </div>
            </div>
            :
            <Table
                rowClassName={(record, index) => {
                    if (props?.newRecord && index === 0) {
                        let a = 'table-row-color';
                        return a;
                    }
                }}
                columns={columns}
                dataSource={dataSource}
                loading={loading}
                search={false}
                onRow={handleNavigation}
                rowClassName={"cursor"}
                onChange={handleTableChange}
            />
    );
};

export default InvoiceTable;
