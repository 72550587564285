import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import { getDriver, updateEmployee } from "../../services/api";

const Driver = () => {
  const [driverInfo, setdriverInfo] = useState({});

  const { id } = useParams();

  useEffect(() => {
    getDriverInformation(id);
  }, []);

  const getDriverInformation = async (id) => {
    try {
      const { data, status } = await getDriver(id);
      if (status) {
        setdriverInfo(data.data);
    
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const [formData, setFormData] = useState({
    name: driverInfo.name,
    email: driverInfo.email,
    mobileNo: driverInfo.mobileNo,
  });

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    updateEmployee(id, formData);
    console.log(formData);
    history.push('/drivers');
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="name">Name: </label>
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
      />
      <label htmlFor="email"> Email: </label>
      <input
        type="text"
        name="email"
        value={formData.email}
        onChange={handleChange}
      />
      <label htmlFor="phone"> Phone: </label>
      <input
        type="text"
        name="mobileNo"
        value={formData.mobileNo}
        onChange={handleChange}
      />
      <input type="submit" value="Update" />
    </form>
  );
};

export default Driver;
