import React, { useEffect, useState } from "react";
import { getAllCustomers, deleteCustomer, disableCustomer } from "../../services/api";
import "./Customers.less";

import {
  Button,
  Col,
  Input,
  Layout,
  Radio,
  Row,
  Space,
  Typography,
  message
} from "antd";
import CustomersTable from "./../../components/tables/CustomersTable";
import { CustomeLoader } from "../../components/loader";

export default function Customers() {
  const [allCustomers, setAllCustomers] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [newRecord, setNewRecord] = useState(false);

  useEffect(() => {
    allCustomersList();
  }, []);

  const allCustomersList = async () => {
    console.log("hello");
    setShowSkeleton(true);
    try {
      const { data, status } = await getAllCustomers();
      if (status) {
        console.log(data.data);
        setAllCustomers(data.data);
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  };

  const deleteACustomer = async (id) => {
    console.log(id);
    setShowSkeleton(true);
    try {
      const { data, status } = await deleteCustomer(id);
      if (status) {
        allCustomersList();
        setShowSkeleton(false);
        message.error("Customer is deleted successfully!")
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  };


  const enableDisableAnCustomer = async (id, isEnable) => {
    setShowSkeleton(true);
    try {
      const { data, status } = await disableCustomer(id, isEnable);
      if (status) {
        allCustomersList();
        setShowSkeleton(false);
        message.success("Customer status changed successfully!")
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  }

  return (
    <div>
      <Layout.Content className="_tableContainer">
        <CustomersTable
          showSkeleton={showSkeleton}
          data={allCustomers}
          newRecord={newRecord}
          deleteACustomer={deleteACustomer}
          enableDisableAnCustomer={enableDisableAnCustomer}
        />
      </Layout.Content>
    </div>
  );
}
