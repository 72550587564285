import { Table, Skeleton, Space, Button, Input, Modal } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./RestaurantsTable.less";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import QRCode from "react-qr-code";
import FileSaver, { saveAs } from "file-saver";
import domtoimage from "dom-to-image";
import { Link } from "react-router-dom";
import { deleteRestaurant, getAllRestaurants } from "../../services/api/index";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;

const RestaurantsTable = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [visitUrl, setVisitUrl] = useState("");
  const [restaurantName, setRestaurantName] = useState("");
  const [tableNumber, setTableNumber] = useState("");
  const [restaurantId, setRestaurantId] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => console.log(node)}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
      // render: name => `${name.first} ${name.last}`,
    },
    {
      title: "Edit",
      dataIndex: "edit",
      sorter: true,
    },
    {
      title: "All Charges",
      dataIndex: "allcharges",
      sorter: true,
    },
    {
      title: "QR",
      dataIndex: "qr",
    },
    {
      title: "Invoice",
      dataIndex: "invoice",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  useEffect(() => {
    const baseUrl =
      process.env.REACT_APP_ENV === "local"
        ? process.env.REACT_APP_URL_LOCAL
        : process.env.REACT_APP_ENV === "dev"
        ? process.env.REACT_APP_URL_TEST
        : process.env.REACT_APP_URL;
    const URL =
      tableNumber === ""
        ? `${baseUrl}order/${restaurantId}`
        : `${baseUrl}order/${restaurantId}?tableNo=${tableNumber}`;
    setVisitUrl(URL);
    // setModal2Visible(true);
  }, [tableNumber]);

  const downloadImage = () => {
    var node = document.getElementById("my-node");
    domtoimage.toBlob(node).then(function (blob) {
      FileSaver.saveAs(blob, "QR-Code.png");
      window.location.reload();
    });
  };

  const handleNavigation = (record) => {
    const id = record.restaurant.uniId;
    setRestaurantId(id);
    console.log(record.restaurant.name);
    setRestaurantName(record.restaurant.name);
    const baseUrl =
      process.env.REACT_APP_ENV === "local"
        ? process.env.REACT_APP_URL_LOCAL
        : process.env.REACT_APP_ENV === "dev"
        ? process.env.REACT_APP_URL_TEST
        : process.env.REACT_APP_URL;
    const URL =
      tableNumber === ""
        ? `${baseUrl}order/${id}`
        : `${baseUrl}order/${id}?tableNo=${tableNumber}`;
    setVisitUrl(URL);
    setModal2Visible(true);
    // history.push(`/dashboard/applicant?id=${record?.checksId}`)
  };

  function showDeleteConfirm(credential) {
    confirm({
      title: "Are you sure delete this restaurant?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        // console.log(credential);
        props.deleteARestaurant(credential);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  const dataSource = [
    ...props.data.map((item, i) => {
      // console.log({ item });

      return {
        key: i,
        name: (item && item.restaurant.name) || "-- --",
        edit: "-- --",
        allcharges: (
          <Link to={`/restaurants/all-charges/${item.restaurant._id}`}>
            <Button type="primary">All Charges</Button>
          </Link>
        ),
        qr: (
          <Button type="primary" onClick={() => handleNavigation(item)}>
            QR
          </Button>
        ),
        invoice: (
          <Link to={`/restaurants/invoice/${item.restaurant._id}`}>
            <Button type="primary">Invoice</Button>
          </Link>
        ),
        action: (
          <Button
            onClick={() => showDeleteConfirm(item.restaurant.credantial)}
            type="dashed"
          >
            Delete
          </Button>
        ),
      };
    }),
  ];

  const handleTableChange = () => {
    console.log("handleTableChange");
  };

  return props.showSkeleton ? (
    <div style={{ padding: "20px" }}>
      <Space
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        justify="space-between"
      >
        <Skeleton.Button
          style={{ width: 120 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Button
          style={{ width: 120 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Button
          style={{ width: 120 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Button
          style={{ width: 120 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Avatar
          style={{ width: 120 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Avatar
          style={{ width: 120 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Avatar
          style={{ width: 120 }}
          active
          size={"small"}
          shape={"square"}
        />
      </Space>
      <div style={{ marginTop: "30px" }}>
        <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
      </div>
      <div style={{ marginTop: "25px" }}>
        <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
      </div>
      <div style={{ marginTop: "25px" }}>
        <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
      </div>
      <div style={{ marginTop: "25px" }}>
        <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
      </div>
      <div style={{ marginTop: "25px" }}>
        <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
      </div>
    </div>
  ) : (
    <>
      <Table
        rowClassName={(record, index) => {
          if (props?.newRecord && index === 0) {
            let a = "table-row-color";
            return a;
          }
        }}
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        search={false}
        // onRow={handleNavigation}
        rowClassName={"cursor"}
        onChange={handleTableChange}
      />
      <Modal
        title="Download QR Code for table top"
        centered
        visible={modal2Visible}
        onOk={downloadImage}
        onCancel={() => setModal2Visible(false)}
        okText="Download"
      >
        <div id="my-node">
          <h3 style={{ textAlign: "center", fontSize: '30px', lineHeight: 'normal' }}>
            Scan Menu to place order <br /> or wait for Waiter
          </h3>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* <h1>Center</h1> */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "400px",
              }}
            >
              <div>
                <QRCode value={visitUrl} size={160} />
                <h3
                  style={{
                    textAlign: "center",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {restaurantName}
                </h3>
              </div>
              <div style={{ justifyContent: "center", alignItems: "center" }}>
                <Input
                  onChange={(e) => setTableNumber(e.target.value)}
                  style={{
                    textAlign: "center",
                    fontSize: "90px",
                    border: "1px solid black",
                    borderRadius: "10px",
                    width: "160px",
                    height: "160px",
                    margin: "0 0 0 15px",
                    color: "black",
                  }}
                />
                <h3
                  style={{
                    textAlign: "center",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  Table Number
                </h3>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RestaurantsTable;
