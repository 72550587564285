import { Layout, Menu, Dropdown, Row, Col, Badge, Space, Avatar, Typography, Breadcrumb } from 'antd';
import { BellOutlined, UserOutlined, MailOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { useContext, useState } from 'react';
import { AuthContext } from '../context/Auth';
import { useHistory, useLocation, Link } from 'react-router-dom'
import { HomeOutlined } from '@ant-design/icons';

const { Header } = Layout;
const { Item } = Menu;
const { Text } = Typography;

function AppHeader({ collapsed }) {
    const history = useHistory();
    const auth = useContext(AuthContext);
    const [count, setCount] = useState(1)
    const { pathname } = useLocation();

    const signout = _ => auth.signOut(_ => history.push('/'))

    const menu = (
        <Menu>
            <Item key="name" icon={<UserOutlined />}>Serati ma</Item>
            <Item key="team" icon={<MailOutlined />}>Invite Team</Item>
            <Item key="settings" icon={<SettingOutlined />}>Settings</Item>
            <Item key="logout" icon={<LogoutOutlined />} onClick={() => signout()}>Sign Out</Item>
        </Menu>
    );

    return (
        <Header className="site-layout-background">
            <Row justify="space-between">
                <Col style={{ paddingLeft: collapsed ? '55px' : '175px' }}>
                    <Space>
                        <Breadcrumb>
                            <Breadcrumb.Item><Link to="/"> <HomeOutlined /></Link></Breadcrumb.Item>
                            {pathname.split('/').map(item => <Breadcrumb.Item>{item === 'dashboard' ? '' : item}</Breadcrumb.Item>)}
                        </Breadcrumb>
                    </Space>
                </Col>
                <Col>
                    <Space>
                        <Badge count={count}>
                            <BellOutlined className='icon' />
                        </Badge>
                        <Dropdown overlay={menu}>
                            <a className="ant-dropdown-link user-avatar">
                                <Avatar src="https://palife.co.uk/wp-content/uploads/2017/11/dummy.jpg" />
                                <Text>
                                    {auth.user?.fullName}
                                </Text>
                            </a>
                        </Dropdown>
                    </Space>
                </Col>
            </Row>
        </Header>
    )
}

export default AppHeader
