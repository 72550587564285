import React, { useState } from "react";
import { Row, Col, Input, Button } from "antd";
import { Space } from "antd";
import { Card, Avatar } from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import "./FixCharges.less";
import {
  getServiceCharges,
  updateServiceCharge,
  getBookingCharges,
  updateBookingCharge,
} from "../../services/api";
import { useEffect } from "react";
import { useHistory } from "react-router";

const FixCharges = () => {
  /**
   * Service Charge Start
   */
  const [serviceCharge, setServiceCharge] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [newRecord, setNewRecord] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    setIsLoading(true);
    setShowSkeleton(true);
    try {
      const { data, status } = await getServiceCharges();
      if (status) {
        // console.log(data.data)
        setServiceCharge(data.data);
        setIsLoading(false);
        setShowSkeleton(false);
      }
    } catch (error) {
      setIsLoading(false);
      setShowSkeleton(false);
      console.log({ error });
    }
  }, []);

  const history = useHistory();

  const [editServiceCharge, setEditServiceCharge] = useState(false);

  const [formData, setFormData] = useState({
    serviceCharge: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateServiceCharge(serviceCharge._id, formData);
    setEditServiceCharge(false);
    setIsLoading(true);
    setShowSkeleton(true);
    try {
      const { data, status } = await getServiceCharges();
      if (status) {
        // console.log(data.data)
        setServiceCharge(data.data);
        setIsLoading(false);
        setShowSkeleton(false);
      }
    } catch (error) {
      setIsLoading(false);
      setShowSkeleton(false);
      console.log({ error });
    }
  };
  /**
   * Service Charge End
   */

  /**
   * Booking Charge Start
   */
  const [bookingCharge, setBookingCharge] = useState([]);

  useEffect(async () => {
    setIsLoading(true);
    setShowSkeleton(true);
    try {
      const { data, status } = await getBookingCharges();
      if (status) {
        // console.log("booking"+data.data.bookingCharge)
        setBookingCharge(data.data);
        setIsLoading(false);
        setShowSkeleton(false);
      }
    } catch (error) {
      setIsLoading(false);
      setShowSkeleton(false);
      console.log({ error });
    }
  }, []);

  const [editBookingCharge, setEditBookingCharge] = useState(false);

  const [formBookingData, setFormBookingData] = useState({
    bookingCharge: "",
  });

  const handleBookingChange = (e) => {
    e.preventDefault();
    setFormBookingData({ ...formBookingData, [e.target.name]: e.target.value });
  };

  const handleBookingSubmit = async (e) => {
    e.preventDefault();
    await updateBookingCharge(bookingCharge._id, formBookingData);
    setEditBookingCharge(false);
    setIsLoading(true);
    setShowSkeleton(true);
    try {
      const { data, status } = await getBookingCharges();
      if (status) {
        console.log("Hello");
        setBookingCharge(data.data);
        setIsLoading(false);
        setShowSkeleton(false);
      }
    } catch (error) {
      setIsLoading(false);
      setShowSkeleton(false);
      console.log({ error });
    }
  };

  return (
    <Row>
      <Col span={12}>
        <Space direction="horizontal" className="justify-content-center">
          <Card
            style={{ width: 500 }}
            className="card"
            cover={
              <div className="card-text">
                <h1 style={{ color: "hsl(234, 12%, 34%)" }}>Booking Charge</h1>
                <h1 style={{ color: "hsl(234, 12%, 34%)" }}>
                  &#163;{bookingCharge?.bookingCharge}
                </h1>
              </div>
            }
            actions={[
              <div>
                {!editBookingCharge && (
                  <Button
                    type="primary"
                    icon={<EditOutlined />}
                    size="large"
                    style={{ background: "#1890ff", borderColor: "#1890ff" }}
                    onClick={() => setEditBookingCharge(true)}
                  >
                    Edit
                  </Button>
                )}
                {editBookingCharge && (
                  <form onSubmit={handleBookingSubmit}>
                    <input
                      type="text"
                      name="bookingCharge"
                      onChange={handleBookingChange}
                    />
                    <button type="primary" style={{ margin: "0 5px" }}>
                      Submit
                    </button>
                  </form>
                )}
              </div>,
            ]}
          ></Card>
        </Space>
      </Col>
      <Col span={12}>
        <Space direction="horizontal" className="justify-content-center">
          <Card
            style={{ width: 500 }}
            className="card"
            cover={
              <div className="card-text">
                <h1 style={{ color: "hsl(234, 12%, 34%)" }}>Service Charge</h1>
                <h1 style={{ color: "hsl(234, 12%, 34%)" }}>
                  &#163;{serviceCharge?.serviceCharge}
                </h1>
              </div>
            }
            actions={[
              <div>
                {!editServiceCharge && (
                  <Button
                    type="primary"
                    icon={<EditOutlined />}
                    size="large"
                    style={{ background: "#1890ff", borderColor: "#1890ff" }}
                    onClick={() => setEditServiceCharge(true)}
                  >
                    Edit
                  </Button>
                )}
                {editServiceCharge && (
                  <form onSubmit={handleSubmit}>
                    <input
                      type="text"
                      name="serviceCharge"
                      onChange={handleChange}
                    />
                    <button type="primary" style={{ margin: "0 5px" }}>
                      Submit
                    </button>
                  </form>
                )}
              </div>,
              // <EditOutlined key="edit" />,
            ]}
          ></Card>
        </Space>
      </Col>
    </Row>
  );
};

export default FixCharges;
