import React from "react";
import { Switch } from "react-router-dom";

// custom
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./publicRoute";
import AppLayout from "../Layout";
import Welcome from "../pages/Welcome";
import Comp404 from "../components/404";

import Dashboard from "../pages/Dashboard";
import AllRestaurants from "../pages/Restaurants/all";
import Types from "../pages/Restaurants/types";
import Allergies from "../pages/Restaurants/allergies";
import PendingOrders from "../pages/Orders/pending";
import InProgress from "../pages/Orders/in-progress";
import AllOrders from "../pages/Orders/all";
import CompletedOrders from "../pages/Orders/completed";
import Customers from "../pages/Customers";
import OrdersPayment from "./../pages/Payments/Orders/index";
import Employees from "./../pages/Employees/index";
import Website from "./../pages/Settings/Website/index";
import AllCharges from "./../pages/Restaurants/all/AllCharges";
import FixCharges from "./../pages/FixCharges/index";
import AllCredentials from "./../pages/Restaurants/AllCredentials/index";
import Invoice from "./../pages/Restaurants/all/Invoice";
import EditEmployee from "./../pages/Employees/EditEmployee";
import EditDrivers from "../pages/Drivers/EditDrivers"
import Drivers from "../pages/Drivers/index"
import BusinessType from "../pages/Restaurants/businessTypes/addType";
import AllBusinessType from "../pages/Restaurants/businessTypes/allTypes";
import AllCuisineType from "../pages/Restaurants/types/allCuisine";

//  API_BASE_URL
// REACT_APP_API_PREFIX = https://sabhi-api-afsfv.ondigitalocean.app/
export default function AppRoutes() {
  return (
    <Switch>
      <PrivateRoute path="/dashboard" exact>
        <AppLayout component={<Dashboard />} />
      </PrivateRoute>
      <PrivateRoute path="/restaurants" exact>
        <AppLayout component={<AllRestaurants />} />
      </PrivateRoute>
      <PrivateRoute path="/restaurants/all" exact>
        <AppLayout component={<AllRestaurants />} />
      </PrivateRoute>
      <PrivateRoute path="/restaurants/all-credentials" exact>
        <AppLayout component={<AllCredentials />} />
      </PrivateRoute>
      <PrivateRoute path="/restaurants/all-charges/:id" exact>
        <AppLayout component={<AllCharges />} />
      </PrivateRoute>
      <PrivateRoute path="/restaurants/invoice/:id" exact>
        <AppLayout component={<Invoice />} />
      </PrivateRoute>
      <PrivateRoute path="/restaurants/cuisine-types" exact>
        <AppLayout component={<Types />} />
      </PrivateRoute>
      <PrivateRoute path="/restaurants/all-cuisine-types" exact>
        <AppLayout component={<AllCuisineType />} />
      </PrivateRoute>
      <PrivateRoute path="/restaurants/business-types" exact>
        <AppLayout component={<BusinessType />} />
      </PrivateRoute>
      <PrivateRoute path="/restaurants/all-business-types" exact>
        <AppLayout component={<AllBusinessType />} />
      </PrivateRoute>
      <PrivateRoute path="/restaurants/allergies" exact>
        <AppLayout component={<Allergies />} />
      </PrivateRoute>
      <PrivateRoute path="/orders" exact>
        <AppLayout component={<AllOrders />} />
      </PrivateRoute>
      <PrivateRoute path="/orders/all" exact>
        <AppLayout component={<AllOrders />} />
      </PrivateRoute>
      <PrivateRoute path="/orders/pending" exact>
        <AppLayout component={<PendingOrders />} />
      </PrivateRoute>
      <PrivateRoute path="/orders/completed" exact>
        <AppLayout component={<CompletedOrders />} />
      </PrivateRoute>
      <PrivateRoute path="/orders/in-progress" exact>
        <AppLayout component={<InProgress />} />
      </PrivateRoute>
      <PrivateRoute path="/customers" exact>
        <AppLayout component={<Customers />} />
      </PrivateRoute>
      <PrivateRoute path="/employees" exact>
        <AppLayout component={<Employees />} />
      </PrivateRoute>
      <PrivateRoute path="/drivers" exact>
        <AppLayout component={<Drivers />} />
      </PrivateRoute>
      <PrivateRoute path="/drivers/edit/:id" exact>
        <AppLayout component={<EditDrivers />} />
      </PrivateRoute>
      <PrivateRoute path="/employee/edit/:id" exact>
        <AppLayout component={<EditEmployee />} />
      </PrivateRoute>
      <PrivateRoute path="/payments/orders" exact>
        <AppLayout component={<OrdersPayment />} />
      </PrivateRoute>
      <PrivateRoute path="/settings/website" exact>
        <AppLayout component={<Website />} />
      </PrivateRoute>
      <PrivateRoute path="/fix-charges" exact>
        <AppLayout component={<FixCharges />} />
      </PrivateRoute>

      {/* User Routes */}
      <PublicRoute exact path="/">
        <Welcome />
      </PublicRoute>
      <PublicRoute path="*">
        <Comp404 />
      </PublicRoute>
    </Switch>
  );
}
