import { Table, Skeleton, Space, Button, Input, Modal } from "antd";
import moment from "moment";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import "./RestaurantsTable.less";
import { LeftCircleFilled, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import QRCode from "react-qr-code";
import FileSaver, { saveAs } from "file-saver";
import domtoimage from "dom-to-image";

const PaymentOrdersTable = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => console.log(node)}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "    Order Number",
      dataIndex: "orderNumber",
      ...getColumnSearchProps("orderNumber"),
      // render: name => `${name.first} ${name.last}`,
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      ...getColumnSearchProps("paymentMethod"),
      // filters: [{ text: 'enableDisable', value: 'enableDisable' }],
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      ...getColumnSearchProps("paymentStatus"),
      // filters: [{ text: 'enableDisable', value: 'enableDisable' }],
    },
    {
      title: "Refund",
      dataIndex: "refund",
    },
    {
      title: "Total Price",
      dataIndex: "totalPrice",
      sorter: true,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
    },
  ];

  const dataSource = [
    ...props.data?.map((item, i) => {
      console.log({ item });

      return {
        key: i,
        orderNumber: (item && item.orderNumber) || "N/A",
        paymentMethod: (item && item.paymentMethod) || "N/A",
        paymentStatus: (item && item.paymentStatus) || "N/A",
        refund:
          (item &&
            item.paymentMethod === "Card" &&
            item.paymentStatus === "Paid" && (
              <LeftCircleFilled
                onClick={() => alert("Refund Button Clicked!")}
              />
            )) ||
          "N/A",
        totalPrice: (item && "£"+Number(item.totalPrice).toFixed(2)) || "N/A",
        createdAt:
          (item && moment(item.createdAt).format("DD-MM-YYYY")) ||
          "N/A",
      };
    }),
  ];

  const handleTableChange = () => {
    console.log("handleTableChange");
  };

  const handleNavigation = (record, index) => {
    return {
      onClick: () => {
        // setModal2Visible(true);
        // history.push(`/dashboard/applicant?id=${record?.checksId}`)
      },
    };
  };

  const downloadImage = () => {
    var node = document.getElementById("my-node");
    domtoimage.toBlob(node).then(function (blob) {
      FileSaver.saveAs(blob, "QR-Code.png");
      window.location.reload();
    });
  };

  return props.showSkeleton ? (
    <div style={{ padding: "20px" }}>
      <Space
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        justify="space-between"
      >
        <Skeleton.Button
          style={{ width: 120 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Button
          style={{ width: 120 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Button
          style={{ width: 120 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Button
          style={{ width: 120 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Avatar
          style={{ width: 120 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Avatar
          style={{ width: 120 }}
          active
          size={"small"}
          shape={"square"}
        />
        <Skeleton.Avatar
          style={{ width: 120 }}
          active
          size={"small"}
          shape={"square"}
        />
      </Space>
      <div style={{ marginTop: "30px" }}>
        <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
      </div>
      <div style={{ marginTop: "25px" }}>
        <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
      </div>
      <div style={{ marginTop: "25px" }}>
        <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
      </div>
      <div style={{ marginTop: "25px" }}>
        <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
      </div>
      <div style={{ marginTop: "25px" }}>
        <Skeleton.Input style={{ width: 1000 }} active size={"small"} />
      </div>
    </div>
  ) : (
    <>
      <Table
        rowClassName={(record, index) => {
          if (props?.newRecord && index === 0) {
            let a = "table-row-color";
            return a;
          }
        }}
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        search={false}
        onRow={handleNavigation}
        rowClassName={"cursor"}
        onChange={handleTableChange}
      />
      <Modal
        title="Download QR Code for table top"
        centered
        visible={modal2Visible}
        onOk={downloadImage}
        onCancel={() => setModal2Visible(false)}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            id="my-node"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "400px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Input
                style={{
                  textAlign: "center",
                  fontSize: "90px",
                  border: "1px solid red",
                  borderRadius: "10px",
                  width: "150px",
                  height: "150px",
                }}
              />
            </div>
            <QRCode value={"https://foodapps.uk"} size={150} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PaymentOrdersTable;
