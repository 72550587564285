import React from "react";
import "../RowCard/RowCard.less"
import {Card, Col, Row } from "antd";

const RowCard = (props) => {

  const {items} = props;
  
  return (
    <div className="site-card-wrapper">
      <Row gutter={16}>
        {items && items.length>0 && items.map((value)=> {
          return(
            <Col span={12}>
              <Card className="card-box" title={value.title} bordered={true}>
                {value.number}
              </Card>
          </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default RowCard;
