import React, { useEffect, useState } from "react";
import {
  deleteRestaurant,
  getAllRestaurantCredentials,
  getAllRestaurants,
} from "../../../services/api";
import "./All.less";
import RestaurantsTable from "./../../../components/tables/RestaurantsTable";

import {
  Button,
  Col,
  Input,
  Layout,
  Radio,
  Row,
  Space,
  Typography,
} from "antd";

export default function AllRestaurants() {
  const [allRestaurants, setAllRestaurants] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [newRecord, setNewRecord] = useState(false);

  useEffect(async () => {
    setShowSkeleton(true);
    try {
      const { data, status } = await getAllRestaurants();
      if (status) {
        console.log(data.data);
        setAllRestaurants(data.data);
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  }, []);

  const deleteARestaurant = async (id) => {
    await deleteRestaurant(id);
    setShowSkeleton(true);
    try {
      const { data, status } = await getAllRestaurants();
      if (status) {
        setAllRestaurants(data.data);
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  };

  return (
    <div>
      <Layout.Content className="_tableContainer">
        <RestaurantsTable
          showSkeleton={showSkeleton}
          data={allRestaurants}
          newRecord={newRecord}
          deleteARestaurant={deleteARestaurant}
        />
      </Layout.Content>
    </div>
  );
}
