import React from "react";
import { useHistory } from "react-router-dom";
import "./Types.less";
import { useState } from "react";
import { createCuisine } from "../../../services/api";

export default function Types() {
  const history = useHistory();
  const [image, setImage] = useState("");
  const [type, setType] = useState("");

  const handleSubmit = (e) => {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("type", type);
    e.preventDefault();
    createCuisine(formData)
    history.push('/restaurants/all-cuisine-types');
  };

  return (
    <div>
      <form onSubmit={handleSubmit} method="post">
        <input
          type="file"
          name="image"
          onChange={(e) => setImage(e.target.files[0])}
        />
        <input
          placeholder="type"
          type="text"
          name="type"
          onChange={(e) => setType(e.target.value)}
        />
        <input type="submit" value="Submit" />
      </form>
      Create Cuisine Form
    </div>
  );
}
