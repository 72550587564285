import React, { Component } from "react";
import { Tabs } from "antd";
import { Editor } from "react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import EditorComponent from "../../../components/EditorComponent";

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

const Website = () => {
  return (
    <Tabs defaultActiveKey="1" onChange={callback}>
      <TabPane tab="Terms & Conditions" key="1">
        <EditorComponent />
      </TabPane>
      <TabPane tab="Covid-19 Guideline" key="2">
        <EditorComponent />
      </TabPane>
      <TabPane tab="FAQs" key="3">
        <EditorComponent />
      </TabPane>
      <TabPane tab="Slider (Home Page)" key="4">
        Slider Home Page
      </TabPane>
      <TabPane tab="Maintenance" key="5">
        Maintenance
      </TabPane>
    </Tabs>
  );
};

export default Website;
