import React, { useContext } from "react";
import logo from "./../assets/logo.png";
import { AuthContext } from "../context/Auth";

import { Menu, Typography, Row, Button, Space } from "antd";
import {
  CreditCardOutlined,
  DollarCircleOutlined,
  FormOutlined,
  InboxOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { BiDish, BiChat } from "react-icons/bi";
import { FaBiking } from "react-icons/fa";
import { AiOutlineCreditCard } from "react-icons/ai";
import { useHistory } from "react-router-dom";
const { SubMenu, Item } = Menu;
const { Title, Text } = Typography;

export default function AppMenu({ collapsed }) {
  const history = useHistory();
  const {
    user: { role },
  } = useContext(AuthContext);

  return (
    <div className="_side-menu-wrapper">
      <Space
        direction="horizontal"
        style={{ width: "100%", justifyContent: "center" }}
      >
        <img src={logo} alt="" style={{ width: "70px", height: "50px" }} />
      </Space>
      <Space
        direction="horizontal"
        style={{ width: "100%", justifyContent: "center" }}
      >
        {!collapsed && <Title level={4}>Dashboard</Title>}
      </Space>

      <div className="_side-menu-layout">
        <Menu theme="light" defaultSelectedKeys={["1"]} mode="inline">
          {/* <Item onClick={() => history.push('/dashboard')} key="dashboard" icon={<DashboardOutlined />}>
                        Dashboard
                    </Item> */}
          <Item
            onClick={() => history.push("/employees")}
            key="employee"
            icon={<UserOutlined />}
          >
            Employee
          </Item>
          <Item
            onClick={() => history.push("/customers")}
            key="customers"
            icon={<UserOutlined />}
          >
            Customers
          </Item>
          <SubMenu key="Restaurants" icon={<BiDish />} title="Restaurants">
            <Item
              key="allRestaurants"
              onClick={() => history.push("/restaurants/all")}
            >
              All Restaurants
            </Item>
            <Item
              key="allCredentials"
              onClick={() => history.push("/restaurants/all-credentials")}
            >
              All Credentials
            </Item>
            
            <SubMenu key="cuisineType"  title="Cuisine Type">
            <Item
              key="cuisineType"
              onClick={() => history.push("/restaurants/all-cuisine-types")}
            >
              All Types
            </Item>
            <Item
              key="addType"
              onClick={() => history.push("/restaurants/cuisine-types")}
            >
              Add Types
            </Item>
            </SubMenu>
            <SubMenu key="businessType"  title="Business Type">
            <Item
              key="businessType"
              onClick={() => history.push("/restaurants/all-business-types")}
            >
              All Types
            </Item>
            <Item
              key="addType"
              onClick={() => history.push("/restaurants/business-types")}
            >
              Add Types
            </Item>
            </SubMenu>
            <Item
              key="allergies"
              onClick={() => history.push("/restaurants/allergies")}
            >
              Allergies
            </Item>
            {/* <Item key="driver-charges" onClick={() => history.push('/restaurants/driver-charges')}>Driver Charges</Item> */}
            <Item
              key="promo-codes"
              onClick={() => history.push("/restaurants/promo-codes")}
            >
              Promo Codes
            </Item>
          </SubMenu>
          <Item
            onClick={() => history.push("/drivers")}
            key="drivers"
            icon={<FaBiking />}
          >
            Drivers
          </Item>
          <SubMenu key="Orders" icon={<FormOutlined />} title="Orders">
            <Item key="allOrders" onClick={() => history.push("/orders/all")}>
              All Orders
            </Item>
            <Item key="pending" onClick={() => history.push("/orders/pending")}>
              Pending
            </Item>
            <Item
              key="in-progress"
              onClick={() => history.push("/orders/in-progress")}
            >
              In Progress
            </Item>
            <Item
              key="completed"
              onClick={() => history.push("/orders/completed")}
            >
              Completed
            </Item>
          </SubMenu>
          <SubMenu key="Payments" icon={<FormOutlined />} title="Payments">
            <Item
              key="allOrders"
              onClick={() => history.push("/payments/orders")}
            >
              Orders
            </Item>
          </SubMenu>
          <Item
            onClick={() => history.push("/fix-charges")}
            key="fixCharges"
            icon={<DollarCircleOutlined />}
          >
            Fix Charges
          </Item>
          <Item
            onClick={() => history.push("/inbox")}
            key="inbox"
            icon={<InboxOutlined />}
          >
            Inbox
          </Item>
          <SubMenu key="live-chat" icon={<BiChat />} title="Live Chat">
            <Item
              key="restaurants"
              onClick={() => history.push("/chat/restaurants")}
            >
              Res. / Takeaways
            </Item>
            <Item key="drivers" onClick={() => history.push("/chat/drivers")}>
              Drivers
            </Item>
            <Item
              key="customers"
              onClick={() => history.push("/chat/customers")}
            >
              Customers
            </Item>
          </SubMenu>
          <SubMenu key="settings" icon={<SettingOutlined />} title="Settings">
            <Item
              key="employee"
              onClick={() => history.push("/settings/employee")}
            >
              Employee
            </Item>
            <Item
              key="website"
              onClick={() => history.push("/settings/website")}
            >
              Website
            </Item>
          </SubMenu>
        </Menu>

        {/* {
                    !collapsed &&
                    <Row justify="center" className="_bottom-menu">
                        <Space direction="vertical">
                            <Text type="secondary" className="_bottom-text" strong>Have a question?</Text>
                            <Button type="dashed">Visit Help Center</Button>
                            <Button type="dashed">Contact support</Button>
                        </Space>
                    </Row>
                } */}
      </div>
    </div>
  );
}
