import axios from "axios";
import React from "react";
import { useHistory } from "react-router-dom";
// import jwtDecode from "jwt-decode"; already installed
import {
    LOGIN,
    GET_ALL_CUSTOMERS,
    GET_ALL_RESTAURANTS,
    GET_ALL_RESTAURANT_CREDENTIALS,
    GET_RESTAURANT_BY_ID,
    GET_ALL_ORDERS,
    GET_SEVEN_DAYS_PAYMENT_ORDERS,
    GET_THIRTY_DAYS_PAYMENT_ORDERS,
    GET_ALL_EMPLOYEES,
    GET_ALL_CHARGES,
    UPDATE_CHARGES,
    DELETE_RESTAURANT,
    POST_CHARGE,
    GET_SERVICE_CHARGES,
    UPDATE_SERVICE_CHARGES,
    POST_SERVICE_CHARGES,
    GET_BOOKING_CHARGES,
    POST_BOOKING_CHARGES,
    UPDATE_BOOKING_CHARGES,
    POST_CHARGES,
    DELETE_ORDER,
    DELETE_CUSTOMER,
    DELETE_EMPLOYEE,
    GET_EMPLOYEE,
    UPDATE_EMPLOYEE,
    ENABLE_EMPLOYEE,
    DISABLE_CUSTOMER,
    GET_ALL_DRIVERS,
    GET_DRIVER,
    GET_SEVEN_DAYS_INVOICES,
    GET_THIRTY_DAYS_INVOICES,
    GET_SEVEN_DAYS_ORDERS,
    GET_THIRTY_DAYS_ORDERS,
    CREATE_CUISINE,
    DELETE_SEVEN_DAYS_ORDERS,
    DELETE_THIRTY_DAYS_ORDERS,
    GET_TODAY_DASHBOARD_INFO,
    GET_SEVEN_DAYS_DASHBOARD_INFO,
    CREATE_ALLERGY_TYPE
} from "../../constants";
import { ALL_BUSINESS_TYPE, ALL_CUISINE, CREATE_BUSINESS_TYPE, DELETE_BUSINESS_TYPE, DELETE_CUISINE, GET_CURRENT_WEEK_PAYMENT_ORDERS, GET_LAST_WEEK_PAYMENT_ORDERS } from "../../constants/endPoints";
;

const {
    REACT_APP_AXIOS_RETRY,
    REACT_APP_API_PREFIX,
    REACT_APP_API_PREFIX_LOCAL,
    REACT_APP_CONTENT_TYPE,
    REACT_APP_API_PREFIX_TEST,
    REACT_APP_APPLICATION_X_WWW_FORM_URLENCODED,
} = process.env;

// Constants
const AXIOS_RETRY = REACT_APP_AXIOS_RETRY;
const API_PREFIX = process.env.REACT_APP_ENV === "local" ? REACT_APP_API_PREFIX_LOCAL : process.env.REACT_APP_ENV === "dev" ? REACT_APP_API_PREFIX_TEST : REACT_APP_API_PREFIX;
const CONTENT_TYPE = REACT_APP_CONTENT_TYPE;
const APPLICATION_X_WWW_FORM_URLENCODED =
    REACT_APP_APPLICATION_X_WWW_FORM_URLENCODED;

const headers = {
    authorization: localStorage.getItem("foodapps-token")
}

console.log({ headers });

export const login = ({ email, password }) => {
    return axios.post(
        `${API_PREFIX}${LOGIN}`,
        { email, password },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

export const getAllRestaurants = () => {
    return axios.get(
        `${API_PREFIX}${GET_ALL_RESTAURANTS}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

export const getLastWeekPaymentOrders = () => {
    return axios.get(
        `${API_PREFIX}${GET_LAST_WEEK_PAYMENT_ORDERS}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

export const getCurrentWeekPaymentOrders = () => {
    return axios.get(
        `${API_PREFIX}${GET_CURRENT_WEEK_PAYMENT_ORDERS}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

export const getAllCustomers = () => {
    return axios.get(
        `${API_PREFIX}${GET_ALL_CUSTOMERS}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

/**
 * Delete Customer
 * @param {Customer Id, String} id 
 * @returns Delete a customer
 */
export const deleteCustomer = (id) => {
    return axios.delete(
        `${API_PREFIX}${DELETE_CUSTOMER}${id}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

/**
 * Disable Customer
 * @param {Customer Id, String} id 
 * @param {isEnable, Object} obj 
 * @returns Disable a customer
 */
 export const disableCustomer = (id, isEnable) => {
    return axios.put(
        `${API_PREFIX}${DISABLE_CUSTOMER}${id}`, isEnable,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

export const getAllEmployees = () => {
    return axios.get(
        `${API_PREFIX}${GET_ALL_EMPLOYEES}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

export const getAllDrivers = () => {
    return axios.get(
        `${API_PREFIX}${GET_ALL_DRIVERS}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};



/**
 * Get Single Employee
 * @param {employee id} id 
 * @returns Single Employee Information
 */
export const getEmployee = (id) => {
    return axios.get(
        `${API_PREFIX}${GET_EMPLOYEE}${id}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

export const getDriver = (id) => {
    return axios.get(
        `${API_PREFIX}${GET_DRIVER}${id}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

/**
 * Update Employee
 * @param {employee id} id 
 * @returns Update Single Employee Information
 */
 export const updateEmployee = (id, formData) => {
    return axios.put(
        `${API_PREFIX}${UPDATE_EMPLOYEE}${id}`, formData,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

/**
 * Delete Employee
 * @param {Employee Id, String} id 
 * @returns Delete an Employee
 */
 export const deleteEmployee = (id) => {
    return axios.delete(
        `${API_PREFIX}${DELETE_EMPLOYEE}${id}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

/**
 * Enable Employee
 * @param {Employee Id, String} id 
 * @param {isEnable, Object} obj 
 * @returns Enable an Employee
 */
 export const enableEmployee = (id, isEnable) => {
    return axios.put(
        `${API_PREFIX}${ENABLE_EMPLOYEE}${id}`, isEnable,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

export const getAllCharges = (id) => {
    return axios.get(
        `${API_PREFIX}${GET_ALL_CHARGES}${id}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

export const addCharges = (formData) => {
    return axios.post(
        `${API_PREFIX}${POST_CHARGES}`, formData,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

/**
 * Charges Update
 * @param {Charge ID, String} id 
 * @param {Edited Charges Value, Object} formData 
 * @returns Update Existing Charges
 */
export const updateCharges = (id, formData) => {
    return axios.put(
        `${API_PREFIX}${UPDATE_CHARGES}${id}`, formData,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

export const updateServiceCharge = (id, formData) => {
    return axios.put(
        `${API_PREFIX}${UPDATE_SERVICE_CHARGES}${id}`, formData,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

/**
 * Booking Charge Update
 * @param {Booking Charge ID, string} id 
 * @param {Booking Charge with Value, Object} formData 
 * @returns Update The Existing Booking Charge
 */
export const updateBookingCharge = (id, formData) => {
    return axios.put(
        `${API_PREFIX}${UPDATE_BOOKING_CHARGES}${id}`, formData,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

export const getAllOrders = (page, limit) => {
    return axios.get(
        `${API_PREFIX}${GET_ALL_ORDERS}?page=${page}&limit=${limit}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

/**
 * Get Last Seven Days Orders
 * @returns Last Seven Days Orders
 */
export const getSevenDaysOrders = () => {
    return axios.get(
        `${API_PREFIX}${GET_SEVEN_DAYS_ORDERS}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

/**
 * Get Last Thirty Days Orders
 * @returns Last Thirty Days Orders
 */
 export const getCurrentWeekOrder = () => {
    return axios.get(
        `${API_PREFIX}${GET_THIRTY_DAYS_ORDERS}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

/**
 * Delete Order
 * @param {Order ID, String} id 
 * @returns Delete the Order
 */
export const deleteOrder = (id) => {
    return axios.delete(
        `${API_PREFIX}${DELETE_ORDER}${id}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

export const getRestaurantById = ({ id }) => {
    return axios.get(
        `${API_PREFIX}${GET_RESTAURANT_BY_ID}${id}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

export const getAllRestaurantCredentials = () => {
    return axios.get(
        `${API_PREFIX}${GET_ALL_RESTAURANT_CREDENTIALS}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

/**
 * Restaurant Credential Delete
 */
export const deleteRestaurant = (credential) => {
    return axios.delete(
        `${API_PREFIX}${DELETE_RESTAURANT}${credential}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

export const postfacharge = (formData) => {
    return axios.post(
        `${API_PREFIX}${POST_CHARGE}`, formData,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

export const getServiceCharges = () => {
    return axios.get(
        `${API_PREFIX}${GET_SERVICE_CHARGES}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

export const getBookingCharges = () => {
    return axios.get(
        `${API_PREFIX}${GET_BOOKING_CHARGES}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

export const postServiceCharge = (formData) => {
    return axios.post(
        `${API_PREFIX}${POST_SERVICE_CHARGES}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

export const postBookingCharge = (formData) => {
    return axios.post(
        `${API_PREFIX}${POST_BOOKING_CHARGES}`, formData,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

/**
 * Get Seven days Restaurant Invoice Based on Restuarant ID
 * @param {Restaurant ID} id 
 * @returns Invoice Orders and Datas
 */
export const getSevenDaysInvoice = (id) => {
    return axios.get(
        `${API_PREFIX}${GET_SEVEN_DAYS_INVOICES}${id}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

/**
 * Get Thirty days Restaurant Invoice Based on Restuarant ID
 * @param {Restaurant ID} id 
 * @returns Invoice Orders and Datas
 */
 export const getThirtyDaysInvoice = (id) => {
    return axios.get(
        `${API_PREFIX}${GET_THIRTY_DAYS_INVOICES}${id}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

/**
 * Create Cuisine
 * @param {formData} Object 
 * @returns Stores Data
 */
 export const createCuisine = (formData) => {
    return axios.post(
        `${API_PREFIX}${CREATE_CUISINE}`, formData,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

/**
 * Create Cuisine
 * @param {formData} Object 
 * @returns Stores Data
 */
 export const createAllergyType = (formData) => {
    return axios.post(
        `${API_PREFIX}${CREATE_ALLERGY_TYPE}`, formData,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};
/**
 * Create business Type
 * @param {formData} Object 
 * @returns Stores Data
 */
 export const createBusinessType = (formData) => {
    
    return axios.post(
        `${API_PREFIX}${CREATE_BUSINESS_TYPE}`, formData,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};
export const getAllBusinessType = () => {
    return axios.get(
        `${API_PREFIX}${ALL_BUSINESS_TYPE}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};
export const getAllCuisine = () => {
    return axios.get(
        `${API_PREFIX}${ALL_CUISINE}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};
export const deleteBusinessType = (credential) => {
    return axios.delete(
        `${API_PREFIX}${DELETE_BUSINESS_TYPE}${credential}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};
export const deleteCuisine = (credential) => {
    console.log(credential)
    return axios.delete(
        `${API_PREFIX}${DELETE_CUISINE}${credential}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};
/**
 * Delete Last 7 Days Orders
 */
 export const deleteSevenDaysOrders = () => {
    return axios.delete(
        `${API_PREFIX}${DELETE_SEVEN_DAYS_ORDERS}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

/**
 * Delete Last 30 Days Orders
 */
 export const deleteThirtyDaysOrders = () => {
    return axios.delete(
        `${API_PREFIX}${DELETE_THIRTY_DAYS_ORDERS}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

/**
 * Get Todays Dashboard Info
 * @returns Dashboard Info of Today
 */
 export const getDashboardInfoToday = () => {
    return axios.get(
        `${API_PREFIX}${GET_TODAY_DASHBOARD_INFO}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

/**
 * Get Seven Days Dashboard Info
 * @returns Dashboard Info of Last Seven Days
 */
 export const getDashboardInfoSevenDays = () => {
    return axios.get(
        `${API_PREFIX}${GET_SEVEN_DAYS_DASHBOARD_INFO}`,
        { headers },
        {
            [AXIOS_RETRY]: {
                retries: 3,
            },
            errorHandling: {
                global: true,
            },
        }
    );
};

