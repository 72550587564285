import React, { useEffect, useState } from "react";
import {
  getCurrentWeekPaymentOrders,
  getLastWeekPaymentOrders,
  getSevenDaysPaymentOrders,
  getThirtyDaysPaymentOrders,
} from "../../../services/api";
import "./Orders.less";

import { Layout, Tabs } from "antd";
import PaymentOrdersTable from "./../../../components/tables/PaymentOrdersTable";

const { TabPane } = Tabs;

export default function OrdersPayment() {
  function callback(key) {
    console.log(key);
  }
  // const [allRestaurants, setAllRestaurants] = useState([])
  // const [showSkeleton, setShowSkeleton] = useState(false);
  // const [newRecord, setNewRecord] = useState(false)

  // useEffect(async () => {
  //     setShowSkeleton(true)
  //     try {
  //         const { data, status } = await getAllRestaurants()
  //         if (status) {
  //             setAllRestaurants(data.data)
  //             setShowSkeleton(false)
  //         }
  //     } catch (error) {
  //         setShowSkeleton(false)
  //         console.log({ error })
  //     }
  // }, [])

  //   const [paymentOrders, setPaymentOrders] = useState([]);
  const [sevenDaysPaymentOrders, setSevenDaysPaymentOrders] = useState([]);
  const [thirtyDaysPaymentOrders, setThirtyDaysPaymentOrders] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [newRecord, setNewRecord] = useState(false);

  useEffect(async () => {
    setShowSkeleton(true);
    try {
      const { data, status } = await getLastWeekPaymentOrders();
      //   console.log({ data });
      if (status) {
        console.log(data.data);
        setSevenDaysPaymentOrders(data.data);
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  }, []);

  useEffect(async () => {
    setShowSkeleton(true);
    try {
      const { data, status } = await getCurrentWeekPaymentOrders();
      //   console.log({ data });
      if (status) {
        console.log(data.data);
        setThirtyDaysPaymentOrders(data.data);
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  }, []);

  return (
    // <div>
    //     <Layout.Content className="_tableContainer">
    //         <PaymentOrdersTable showSkeleton={showSkeleton} data={paymentOrders} newRecord={newRecord} />
    //     </Layout.Content>
    // </div>

    <div>
      {/* <Tabs onChange={callback} type="card">
        <Tabs Tabs="Last 7 Days Orders" key="1">
          <Layout.Content className="_tableContainer">
            <PaymentOrdersTable
              showSkeleton={showSkeleton}
              data={sevenDaysPaymentOrders}
              newRecord={newRecord}
            />
          </Layout.Content>
        </Tabs>
        <TabPane Tabs="Last 30 Days Orders" key="2">
          <Layout.Content className="_tableContainer">
            <PaymentOrdersTable
              showSkeleton={showSkeleton}
            //   data={paymentOrders}
              newRecord={newRecord}
            />
          </Layout.Content>
        </TabPane>
      </Tabs> */}
      <Tabs onChange={callback} type="card">
        <TabPane tab="Current Week Payment Orders" key="2">
          <Layout.Content className="_tableContainer">
            <PaymentOrdersTable
              showSkeleton={showSkeleton}
              data={thirtyDaysPaymentOrders}
              newRecord={newRecord}
            />
          </Layout.Content>
        </TabPane>
        <TabPane tab="Last Week Payment Orders" key="1">
          <Layout.Content className="_tableContainer">
            <PaymentOrdersTable
              showSkeleton={showSkeleton}
              data={sevenDaysPaymentOrders}
              newRecord={newRecord}
            />
          </Layout.Content>
        </TabPane>
        
      </Tabs>
    </div>
  );
}
