import React, { useState } from "react";
import { useEffect } from "react";
import {  deleteCuisine, getAllCuisine,  } from "../../../services/api";
import { Layout } from "antd";
import CuisineTypeTable from "../../../components/tables/AllCuisineTypeTable";

const AllCuisineType = () => {
  const [allType, setAllType] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [newRecord, setNewRecord] = useState(false);

  useEffect(() => {
    AllCuisineType();
  }, []);

  const AllCuisineType = async () => {
    
    setShowSkeleton(true);
    try {
      const { data, status } = await getAllCuisine();
      if (status) {
        console.log(data.data);
        setAllType(data.data);
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  };

  const deleteCredential = async (id) => {
    // console.log(id);
    setShowSkeleton(true);
    try {
      const { status } = await deleteCuisine(id);
      if (status) {
    
        AllCuisineType();
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.error({ error });
    }
  };

  return (
    <div>
      <Layout.Content className="_tableContainer">
        <CuisineTypeTable
          showSkeleton={showSkeleton}
          data={allType}
          newRecord={newRecord}
          deleteCredential={deleteCredential}
        />
      </Layout.Content>
    </div>
  );
};

export default AllCuisineType;
