import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import { getEmployee, updateEmployee } from "../../services/api";
import { Form, Input, Button, Checkbox, Switch } from "antd";

const EditEmployee = () => {
  const [employeeInfo, setEmployeeInfo] = useState({});

  const { id } = useParams();

  useEffect(() => {
    getEmployeeInformation(id);
  }, []);

  const getEmployeeInformation = async (id) => {
    try {
      const { data, status } = await getEmployee(id);
      if (status) {
        setEmployeeInfo(data.data);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const [formData, setFormData] = useState({
    name: employeeInfo.name,
    email: employeeInfo.email,
    mobileNo: employeeInfo.mobileNo,
  });

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    updateEmployee(id, formData);
    console.log(formData);
    history.push('/employees');
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="name">Name: </label>
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
      />
      <label htmlFor="email"> Email: </label>
      <input
        type="text"
        name="email"
        value={formData.email}
        onChange={handleChange}
      />
      <label htmlFor="phone"> Phone: </label>
      <input
        type="text"
        name="mobileNo"
        value={formData.mobileNo}
        onChange={handleChange}
      />
      <input type="submit" value="Update" />
    </form>
  );
};

export default EditEmployee;
