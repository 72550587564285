import { useEffect, useState } from 'react';

import { Tabs, Col, Form, Input, Typography, Select, Checkbox, Button } from 'antd';

const { Item } = Form;
const { Option } = Select;
const { Text } = Typography;

const prefixSelector = (
	<Item name="prefix" noStyle>
		<Select defaultValue="+92" style={{ width: 70 }}>
			<Option value="92">+92</Option>
		</Select>
	</Item>
);

function LoginSignup({
	login,
	setShowPage,
	type,
	setType,
	company,
	email
}) {
	const [phoneNumber, setPhoneNumber] = useState("afasfa");

	// const PHONE_REGEX = /^(?:\d{8}(?:\d{2}(?:\d{2})?)?|\(\+?\d{2,3}\)\s?(?:\d{4}[\s*.-]?\d{4}|\d{3}[\s*.-]?\d{3}|\d{2}([\s*.-]?)\d{2}\1\d{2}(?:\1\d{2})?))$/;
	// const validatePhone = (value) => value.length === 11 && PHONE_REGEX.test(value);

	const [form] = Form.useForm()
	useEffect(() => {
		form.setFieldsValue({
			companyname: "company",
			email: "email",
		});
	}, [])

	return (
		<div>
			<Col>
				{/* <Tabs activeKey={type} size="large" onChange={setType} centered>
					<Tabs.TabPane key="login" tab="Login" /> */}
					{/* <Tabs.TabPane key="signup" tab="Sign up" /> */}
				{/* </Tabs> */}
				<br />

				{type === 'login' && (
					<Form
						className="container"
						onFinish={async (values) => {
							await login(values);
						}}
					>
						<Item
							name="email"
							rules={[
								{
									type: 'email',
									message: 'email not valid!',
								},
								{
									required: true,
									message: ' ',
								},
							]}
						>
							<Input placeholder="Email" />
						</Item>
						<Item
							name="password"
							rules={[{ required: true, message: ' ' }, { min: 3 }]}
						>
							<Input.Password placeholder="Password" />
						</Item>
						<div className='remember-me'>
							<Item name="remember" valuePropName="checked">
								<Checkbox>
									Remember me
								</Checkbox>
							</Item>
							<a onClick={() => setShowPage("forgotPassword")}>
								<Text
									underline
									style={{
										color: '#1890FF',
									}}
								>
									Forgot password?
								</Text>
							</a>
						</div>
						<Button type="primary" htmlType="submit" className="full-width">
							Login
						</Button>
					</Form>
				)}

			</Col>
		</div>
	)
}

export default LoginSignup
