import React, { useEffect, useState } from "react";
import { getAllRestaurants, updateServiceCharge } from "../../../services/api";
import "./All.less";
import RestaurantsTable from "../../../components/tables/RestaurantsTable";
import { Card } from "antd";
import { Button, Col, Layout, Radio, Row, Space, Typography } from "antd";
import ChargesTable from "./../../../components/tables/ChargesTable";
import {
  getAllCharges,
  addCharges,
  updateCharges,
} from "./../../../services/api/index";
import { EditOutlined } from "@ant-design/icons";
import { Form, Input, Checkbox } from "antd";
import { useParams } from "react-router-dom";

export default function AllCharges() {
  const { id } = useParams();

  const [allCharges, setAllCharges] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [newRecord, setNewRecord] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    setIsLoading(true);
    setShowSkeleton(true);
    try {
      const { data, status } = await getAllCharges(id);
      if (status) {
        console.log(data.data);
        setAllCharges(data.data);
        setIsLoading(false);
        setShowSkeleton(false);
      }
    } catch (error) {
      setIsLoading(false);
      setShowSkeleton(false);
      console.log({ error });
    }
  }, []);

  const [formData, setFormData] = useState({
    faCharge: "",
    riderChargeInLimit: "",
    dineInCharge: "",
    inhouseFaCharge: "",
    riderChargeOffLimit: "",
    restaurant: id,
  });

  const [updatedFormData, setUpdatedFormData] = useState({
    faCharge: "",
    riderChargeInLimit: "",
    dineInCharge: "",
    inhouseFaCharge: "",
    riderChargeOffLimit: "",
    restaurant: id,
  });

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleUpdateChange = (e) => {
    e.preventDefault();
    setUpdatedFormData({ ...updatedFormData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addCharges(formData);
    setIsLoading(true);
    setShowSkeleton(true);
    try {
      const { data, status } = await getAllCharges(id);
      if (status) {
        console.log(data.data);
        setAllCharges(data.data);
        setIsLoading(false);
        setShowSkeleton(false);
      }
    } catch (error) {
      setIsLoading(false);
      setShowSkeleton(false);
      console.log({ error });
    }
  };

  const [editCharges, setEditCharges] = useState(false);

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    // console.log(formData);
    // console.log(allCharges[0]._id);
    await updateCharges(id, updatedFormData);
    setIsLoading(true);
    setShowSkeleton(true);
    try {
      const { data, status } = await getAllCharges(id);
      if (status) {
        console.log(data.data);
        setAllCharges(data.data);
        setIsLoading(false);
        setShowSkeleton(false);
      }
    } catch (error) {
      setIsLoading(false);
      setShowSkeleton(false);
      console.log({ error });
    }
    setEditCharges(false);
  };

  return (
    <div>
      {allCharges.length === 0 ? (
        <div>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="FA Charge"
              name="faCharge"
              onChange={handleChange}
            />
            <input
              type="text"
              placeholder="Inhouse FA Charge"
              name="inhouseFaCharge"
              onChange={handleChange}
            />
            <input
              type="text"
              placeholder="Rider Charge Within 3 Miles"
              name="riderChargeInLimit"
              onChange={handleChange}
            />
            <input
              type="text"
              placeholder="Rider Charge Over 3 Miles"
              name="riderChargeOffLimit"
              onChange={handleChange}
            />
            <input
              type="text"
              placeholder="Dine Charge"
              name="dineInCharge"
              onChange={handleChange}
            />
            <input type="submit" value="Add Charges" />
          </form>
          <br/>
          <br/>
          <br/>
        </div>
      ) : (
        <Row>
          <Col span={6}>
            <Space direction="horizontal" className="justify-content-center">
              <Card
                style={{ width: 300 }}
                className="card"
                cover={
                  <div className="card-text">
                    <h3 style={{ color: "hsl(234, 12%, 34%)" }}>
                      FA <br /> Charge
                    </h3>
                    <h1 style={{ color: "hsl(234, 12%, 34%)" }}>
                      {allCharges[0].faCharge}%
                    </h1>
                  </div>
                }
              ></Card>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="horizontal" className="justify-content-center">
              <Card
                style={{ width: 300 }}
                className="card"
                cover={
                  <div className="card-text">
                    <h3 style={{ color: "hsl(234, 12%, 34%)" }}>
                      In-House FA <br /> Charge
                    </h3>
                    <h1 style={{ color: "hsl(234, 12%, 34%)" }}>
                      &#163; {allCharges[0].inhouseFaCharge}
                    </h1>
                  </div>
                }
              ></Card>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="horizontal" className="justify-content-center">
              <Card
                style={{ width: 300 }}
                className="card"
                cover={
                  <div className="card-text">
                    <h1 style={{ color: "hsl(234, 12%, 34%)" }}>
                      Rider Charge Within 3 Miles
                    </h1>
                    <h1 style={{ color: "hsl(234, 12%, 34%)" }}>
                      &#163;{allCharges[0].riderChargeInLimit}
                    </h1>
                  </div>
                }
              ></Card>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="horizontal" className="justify-content-center">
              <Card
                style={{ width: 300 }}
                className="card"
                cover={
                  <div className="card-text">
                    <h1 style={{ color: "hsl(234, 12%, 34%)" }}>
                      Rider Charge Over 3 Miles
                    </h1>
                    <h1 style={{ color: "hsl(234, 12%, 34%)" }}>
                      &#163;{allCharges[0].riderChargeOffLimit}
                    </h1>
                  </div>
                }
              ></Card>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="horizontal" className="justify-content-center">
              <Card
                style={{ width: 300 }}
                className="card"
                cover={
                  <div className="card-text">
                    <h1 style={{ color: "hsl(234, 12%, 34%)" }}>
                      Dine in Charge
                    </h1>
                    <h1 style={{ color: "hsl(234, 12%, 34%)" }}>
                      &#163;{allCharges[0].dineInCharge}
                    </h1>
                  </div>
                }
              ></Card>
            </Space>
          </Col>
        </Row>
      )}
      {!editCharges ? (
        <div className="center">
          <button className="red-button" onClick={() => setEditCharges(true)}>
            Edit
          </button>
        </div>
      ) : (
        <div>
          <form onSubmit={handleUpdateSubmit}>
            <input
              type="text"
              placeholder="FA Charge"
              name="faCharge"
              onChange={handleUpdateChange}
            />
            <input
              type="text"
              placeholder="In House FA Charge"
              name="inhouseFaCharge"
              onChange={handleUpdateChange}
            />
            <input
              type="text"
              placeholder="Rider Charge Within 3 Miles"
              name="riderChargeInLimit"
              onChange={handleUpdateChange}
            />
            <input
              type="text"
              placeholder="Rider Charge Over 3 Miles"
              name="riderChargeOffLimit"
              onChange={handleUpdateChange}
            />
            <input
              type="text"
              placeholder="Dine In Charge"
              name="dineInCharge"
              onChange={handleUpdateChange}
            />
            <input type="submit" value="Update Charges" />
          </form>
        </div>
      )}
      <br/>
      <br/>
      <br/>
    </div>
  );
}
