import { Select } from 'antd';
import React from 'react';
const { Option } = Select;
const children = [];
const options = ['Book A Table','Collection','Dine In','Home Delivery'];


for (let i = 0; i < options.length; i++) {
    children.push(<Option key={options[i]}>{options[i]}</Option>)
}
const OrderType = ({setOption}) => (
    
  <>
    <Select
      mode="multiple"
      allowClear
      style={{
        width: '20%',
      }}
      placeholder="Please select"
    //   defaultValue={['a10', 'c12']}
      onChange={(value)=>setOption(value)}
    >
      {children}
    </Select>
    
  </>
);

export default OrderType;