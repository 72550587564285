import React from 'react'
import './Completed.less'

export default function CompletedOrders() {
    return (
        <div>
            Completed
        </div>
    )
}
