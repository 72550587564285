import React from "react";
// import "./Types.less";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { createBusinessType } from "../../../services/api";
import OrderType from "./OrderType";

export default function BusinessType() {
  const [businessType, setType] = useState('');
  const [options, setOption] = useState([]);
  
  const history = useHistory();
  const formData = {
    businessType: businessType,
    orderTypes: options
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    createBusinessType(formData)
    history.push('/restaurants/all-business-types')
    
  };

  return (
    <div>
      <form onSubmit={handleSubmit} method="post">
        <p>Create Business Type</p>
        <span>Types: </span><input
          placeholder="Business Type"
          type="text"
          name="businessType"
          onChange={(e) => setType(e.target.value)}
        />
        <br />
        <br />
        <div className="">
          Order Types:<OrderType setOption={setOption} />
        </div>
        <input type="submit" value="Submit" />
      </form>

    </div>
  );
}
