import React, { useEffect, useState } from "react";
import { getAllDrivers} from "../../services/api";
import "./Drivers.less";
import {Layout} from "antd";
import DriversTable from "./../../components/tables/DriversTable";

export default function Driver() {
  const [allDrivers, setallDrivers] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [newRecord, setNewRecord] = useState(false);

  useEffect(() => {
    allDriversList();
  }, []);

  const allDriversList = async () => {
    // console.log("hello");
    setShowSkeleton(true);
    try {
      const { data, status } = await getAllDrivers();
      if (status) {
        console.log("driver",data.data);
        setallDrivers(data.data);
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  };
  return (
    <div>
      <Layout.Content className="_tableContainer">
        <DriversTable
          showSkeleton={showSkeleton}
          data={allDrivers}
          newRecord={newRecord}
        />
      </Layout.Content>
    </div>
  );
}
