import React, { useState } from "react";
import { useEffect } from "react";
import { deleteRestaurant, getAllRestaurants } from "../../../services/api";
import { Layout } from "antd";
import RestaurantsTable from "./../../../components/tables/RestaurantsTable";
import CredentialsTable from "./../../../components/tables/CredentialsTable";
import { getAllRestaurantCredentials } from "./../../../services/api/index";

const AllCredentials = () => {
  const [allCredentials, setAllCredentials] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [newRecord, setNewRecord] = useState(false);

  useEffect(() => {
    allRestaurantCredentials();
  }, []);

  const allRestaurantCredentials = async () => {
    console.log("hello");
    setShowSkeleton(true);
    try {
      const { data, status } = await getAllRestaurantCredentials();
      if (status) {
        console.log(data.data);
        setAllCredentials(data.data);
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  };

  const deleteCredential = async (id) => {
    console.log(id);
    setShowSkeleton(true);
    try {
      const { data, status } = await deleteRestaurant(id);
      if (status) {
        console.log(data.data);
        setAllCredentials(data.data);
        allRestaurantCredentials();
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.log({ error });
    }
  };

  return (
    <div>
      <Layout.Content className="_tableContainer">
        <CredentialsTable
          showSkeleton={showSkeleton}
          data={allCredentials}
          newRecord={newRecord}
          deleteCredential={deleteCredential}
        />
      </Layout.Content>
    </div>
  );
};

export default AllCredentials;
