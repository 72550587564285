export const LOGIN = "super-admin/signin/";
export const GET_ALL_RESTAURANTS = "restaurant/get-all-restaurants/";
export const GET_ALL_RESTAURANT_CREDENTIALS =
  "restaurant/get-all-restaurant-credentials/";
export const GET_ALL_CUSTOMERS = "customer/get-all-customers/";
export const POST_CHARGES = "charge/set-charge";
export const GET_ALL_CHARGES = "charge/get-all-charges/";
export const UPDATE_CHARGES = "charge/edit-charge/";
export const GET_ALL_EMPLOYEES = "employee/get-all-employees/";
export const GET_RESTAURANT_BY_ID = "restaurant/get-restaurant-info/";
export const GET_ALL_ORDERS = "order/get-all-orders";
export const GET_LAST_WEEK_PAYMENT_ORDERS = "order/get-last-week-payment";
export const GET_CURRENT_WEEK_PAYMENT_ORDERS = "order/get-current-week-payment";
export const DELETE_RESTAURANT = "restaurant/delete-restaurant/";
export const POST_CHARGE = "charge/set-charge";
export const GET_SERVICE_CHARGES = "service-charge/get-service-charge";
export const GET_BOOKING_CHARGES = "booking-charge/get-booking-charge";
export const UPDATE_SERVICE_CHARGES = "service-charge/update-service-charge/";
export const UPDATE_BOOKING_CHARGES = "booking-charge/update-booking-charge/";
export const POST_SERVICE_CHARGES = "service-charge/set-service-charge";
export const POST_BOOKING_CHARGES = "booking-charge/set-booking-charge";
export const DELETE_ORDER = "order/delete-order/";
export const DELETE_CUSTOMER = "customer/delete-customer/";
export const DELETE_EMPLOYEE = "employee/delete-employee/";
export const GET_EMPLOYEE = "employee/get-single-employee/";
export const GET_DRIVER = "driver/get-driver-by-id";
export const UPDATE_EMPLOYEE = "employee/update-employee/";
export const ENABLE_EMPLOYEE = "employee/employee-change-state/";
export const DISABLE_CUSTOMER = "customer/customer-change-state/";
export const GET_ALL_DRIVERS = "driver/get-all-drivers";
export const GET_SEVEN_DAYS_INVOICES = "order/get-restaurant-invoice-7-days-by-super-admin/";
export const GET_THIRTY_DAYS_INVOICES = "order/get-restaurant-invoice-30-days-by-super-admin/";
export const GET_SEVEN_DAYS_ORDERS = "order/get-last-7-days-order-super-admin";
export const GET_THIRTY_DAYS_ORDERS = "order/get-current-week-order-super-admin";
export const CREATE_BUSINESS_TYPE = "business-type/create-business-type";
export const ALL_BUSINESS_TYPE = "business-type/get-all-business-type";
export const CREATE_CUISINE = "cuisine/create-cuisine";
export const ALL_CUISINE = "cuisine/get-all-cuisines";
export const DELETE_CUISINE = "cuisine/delete-cuisine/";
export const DELETE_BUSINESS_TYPE = "business-type/delete-business-type/";
export const DELETE_SEVEN_DAYS_ORDERS = "order/delete-order-of-week";
export const DELETE_THIRTY_DAYS_ORDERS = "order/delete-order-of-month";
export const GET_TODAY_DASHBOARD_INFO = "dashboard/get-today-dashboard";
export const GET_SEVEN_DAYS_DASHBOARD_INFO = "dashboard/get-last-7-days-dashboard";
export const CREATE_ALLERGY_TYPE = "allergy/add-allergy";
